import { Plugins } from "@capacitor/core";
import { config } from "../Constants";
const { Storage } = Plugins;

export const crashSendLog = async (
  error: string,
  info: string,
  componentStack: string
) => {
  const userData = await Storage.get({ key: "userData" });
  const Token = userData.value ? JSON.parse(userData.value).Token : "";
  const message =
    " ~~ Error ~~ : " +
    error +
    ". ~~ Info ~~ : " +
    info +
    ". ~~ ComponentStack ~~ : " +
    componentStack;
  var data = new URLSearchParams({
    message: message,
  }).toString();

  return new Promise((resolve) => {
    fetch(`${config.url.API_URL}/log/writeLog`, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        authorization: Token,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Crash Send Log OK");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
