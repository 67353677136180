import React, { useState } from "react";
import HeaderContext, { HeaderParameters } from "./Header-Context";

import CustomHeader from "../components/utils/CustomElements/CustomHeader/Header";

const HeaderContextProvider: React.FC = (props) => {
  const [headerParameters, setHeaderParameters] = useState<HeaderParameters>(
    {}
  );

  const initHeader = (headerParameters: HeaderParameters) => {
    setHeaderParameters(headerParameters);
  };

  return (
    <HeaderContext.Provider
      value={{
        headerParameters,
        initHeader,
      }}
    >
      <CustomHeader headerParameters={headerParameters}></CustomHeader>

      {props.children}
    </HeaderContext.Provider>
  );
};

export default HeaderContextProvider;
