import React, { useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsEventsDetails } from "../services/VendorDetailsService";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
// import CustomDataGrid from "./utils/CustomElements/CustomDataGrid/CustomDataGrid";
// import { GridColDef } from "@material-ui/data-grid";
// import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import { isPlatform } from "@ionic/core";
import { isMobile } from "react-device-detect";

const ActivityRaw: React.FC<{
  show: boolean;
  closeModal: (isDeleted: boolean) => void;
  referer_id: string;
  cat_code: string;
  area_code: string;
  referer_desc: string;
  startDate: Date;
  endDate: Date;
}> = (props) => {
  const [ActivityRaw, setActivityRaw] = useState<any>([]);
  const [sums, setSums] = useState<any>([]);

  const headerParameters: HeaderParameters = {
    modalType: "modal",
    isBreadCrumbs: true,
  };

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.startDate
  );

  const [selectedEndDate, setSelectedEndDate] = useState<Date>(props.endDate);

  useEffect(() => {
    getVendorsEventsDetails(
      props.referer_id,
      props.cat_code,
      props.area_code,
      false,
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate)
    ).then((result) => {
      getVendorsEventsDetails(
        props.referer_id,
        props.cat_code,
        props.area_code,
        true,
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate)
      ).then((result) => {
        setSums(result);
      });
      setActivityRaw(result);
    });
  }, []);

  const columns = [
    {
      field: "id",
      title: "מפתח איוונט",
      cellStyle: {
        width: "5%",
      },
    },
    {
      field: "event_type",
      title: "סוג איוונט",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "event_count",
      title: "איוונטים",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "is_fraud",
      title: "הונאה",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "is_above_budget",
      title: "מעל תקציב",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "is_blacklist",
      title: "רשימה שחורה",
      cellStyle: {
        width: "12%",
      },
    },
  ];

  return (
    <CustomModal
      pageName={"TrafficSourcePage"}
      headerParameters={headerParameters}
      title={isMobile ? props.referer_desc + " > פירוט איוונטים" : ""}
      closeModal={() => {
        props.closeModal(false);
      }}
      isOpen={props.show}
    >
      <CustomMaterialTable
        rows={ActivityRaw}
        columns={columns}
        onSearchClick={(startDate: Date, endDate: Date) => {
          setSelectedStartDate(startDate);
          setSelectedEndDate(endDate);
        }}
        // onSearchClick={() => {
        //   getVendorsEventsDetails(
        //     props.referer_id,
        //     false,
        //     getDateReverseFormat(selectedStartDate),
        //     getDateReverseFormat(selectedEndDate)
        //   ).then((result) => {
        //     getVendorsEventsDetails(
        //       props.referer_id,
        //       true,
        //       getDateReverseFormat(selectedStartDate),
        //       getDateReverseFormat(selectedEndDate)
        //     ).then((result) => {
        //       setSums(result);
        //     });
        //     setActivityRaw(result);
        //   });
        // }}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        summaryTbl={sums}
        title={
          isMobile ? "פירוט איוונטים" : props.referer_desc + " > פירוט איוונטים"
        }
      />
    </CustomModal>
  );
};

export default ActivityRaw;
