import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  IonSpinner,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonCard,
} from "@ionic/react";
import {
  addCircleOutline,
  calendarOutline,
  create,
  pencil,
  addCircle,
  radioButtonOn,
  closeCircle,
  pauseCircle,
  search,
} from "ionicons/icons";
import { toast } from "react-toastify";
import { Chart } from "react-google-charts";
import Cookies from "universal-cookie";
import moment from "moment";

//CustomElements
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import CustomDateRangePicker from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import CustomForm from "./utils/CustomElements/CustomForm";
import CustomAlert, {
  ButtonProperties,
} from "./utils/CustomElements/CustomAlert";
import CustomInput from "./utils/CustomElements/CustomInput";
import CustomPublishButton from "./utils/CustomElements/CustomPublishButton";
import CustomToggle from "./utils/CustomElements/CustomToggle";
import CustomAutocomplete from "./utils/CustomElements/CustomAutocomplete";

//func
import {
  getCampaigns,
  getChartData,
  getProducts,
  insertRequestToChangeStatusCampaign,
  addCampaign,
  updateCampaign,
  getWaitingCampaigns,
  getLocations,
  updateRequestToChangeStatusCampaign,
  checkIfHasRequestToCampaign,
  getApplicationError,
  getPeriodData,
} from "../services/DataServices";
import {
  getDateFormat,
  getDateReverseFormat,
} from "../utils-ts/GlobalDateFunctions";

//data
import VendorDetailsContext from "../data/VendorDetails-context";
import UserContext from "../data/AuthContextProvider";
import { HeaderParameters } from "../data/Header-Context";

const Campaigns: React.FC = (props) => {
  const { id } = useParams() as { id: string };
  const [campaigns, setCampaigns] = useState<any>([]);
  const [waitingCampaigns, setWaitingCampaigns] = useState<any>([]);
  const [isCampaignsLoader, setIsCampaignsLoader] = useState(false);
  const [isWaitingCampaignsLoader, setIsWaitingCampaignsLoader] =
    useState(false);
  const [periodData, setPeriodData] = useState<any>([]);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [isDateRangeShow, setDateRangeShow] = useState<boolean>(false);
  const [campaignGraphShow, setCampaignGraphShow] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>("");
  const [chartData, setChartData] = useState<any>([]);
  const [chartDataOrg, setChartDataOrg] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [selectedProductId, setSelectedProductId] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<any>("");
  const [selectedChannel, setSelectedChannel] = useState<number>(-1);
  const [scheduled, setScheduled] = useState<number>(0);
  const [accentColor, setAccentColor] = useState<string>("");
  const [mainColor, setMainColor] = useState<string>("");

  const [campaignEditId, setCampaignEditId] = useState<string>("");
  const [campaignEditRow, setCampaignEditRow] = useState<any>("");

  const [showCreateCampaignModal, setShowCreateCampaignModal] =
    useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorRequest, setErrorRequest] = useState("");

  const [cost, setCost] = useState<number>(0);
  const [clicks, setClicks] = useState<number>(0);
  const [impressions, setImpressions] = useState<number>(0);
  const [conversions, setConversions] = useState<number>(0);

  const [showCost, setShowCost] = useState<boolean>(true);
  const [showClicks, setShowClicks] = useState<boolean>(true);
  const [showConversions, setShowConversions] = useState<boolean>(true);
  const [showImpressions, setShowImpressions] = useState<boolean>(true);

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  const VendorDetailsCxt = useContext(VendorDetailsContext);
  const { authValues } = useContext(UserContext);
  const cookies = new Cookies();

  const { handleSubmit, formState, errors, control, register } = useForm({
    mode: "onChange",
  });

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  const ErrorMessageButton: ButtonProperties[] = [
    {
      text: "אישור",
      role: "ok",
      handler: () => {
        setShowAlert(false);
        setError("");
      },
    },
  ];

  const columns = [
    {
      title: "",
      field: "campaign_id",
      cellStyle: {
        width: "50px",
        textAlign: "center",
        minWidth: "50px",
      },
      render: (row: any) => {
        return (
          <IonIcon
            size="large"
            icon={search}
            slot="end"
            onClick={() => {
              setSelectedCampaign(row);
              setCampaignGraphShow(true);
              getChartData(
                getDateReverseFormat(selectedStartDate),
                getDateReverseFormat(selectedEndDate),
                row.campaign_id
              ).then((res) => {
                //console.log(res);
                setTimeout(() => {
                  setChartData(res);
                  setChartDataOrg(res);
                }, 1000);
              });
            }}
            style={{ cursor: "pointer" }}
          ></IonIcon>
        );
      },
      filtering: false,
    },
    {
      title: "סטטוס",
      field: "status",
      cellStyle: {
        width: "50px",
        textAlign: "center",
        // minWidth: "50px",
      },
      render: (row: any) => {
        if (row.status == 1) {
          //פעיל
          return (
            <IonIcon
              size="large"
              icon={radioButtonOn}
              style={{ color: "green" }}
              slot="end"
            ></IonIcon>
          );
        } else if (row.status == 2) {
          //מחוק
          return (
            <IonIcon
              size="large"
              icon={closeCircle}
              style={{ color: "red" }}
              slot="end"
            ></IonIcon>
          );
        } else {
          //לא פעיל
          return (
            <IonIcon
              size="large"
              icon={pauseCircle}
              style={{ color: "grey" }}
              slot="end"
            ></IonIcon>
          );
        }
      },
    },
    {
      title: "",
      field: "status",
      cellStyle: {
        width: "16%",
        minWidth: "70px",
        textAlign: "right",
      },
      render: (row: any) => {
        return (
          <>
            <button
              style={{
                width: "100%",
                height: "30px",
                fontWeight: "bold",
                borderRadius: "10px",
                backgroundColor: "lightgrey", //row.status == 1 ? "red" : "green",
              }}
              onClick={() => {
                changeStatusCampaign(row);
              }}
              disabled={row.status == 2}
            >
              {row.status == 1 ? "עצור" : "הפעל"}
            </button>
            <button
              style={{
                width: "100%",
                height: "30px",
                fontWeight: "bold",
                borderRadius: "10px",
                backgroundColor: "grey",
                marginTop: "10px",
              }}
              onClick={() => {
                changeCampaignDeleted(row);
              }}
              disabled={row.status == 2}
            >
              מחק
            </button>
          </>
        );
      },
    },
    {
      title: "שם קמפיין",
      field: "campaignName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "תקציב שנוצל",
      field: "cost",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      render: (row: any) => {
        return <div>{row.cost.toFixed(2)}</div>;
      },
    },
    {
      title: "המרות",
      field: "conversions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "חשיפות",
      field: "impressions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "עלות ממוצעת להמרה",
      field: "costConv",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },

    // {
    //   title: "כניסות לנכסים דיגיטליים",
    //   field: "EntriesToDigitalAssets",
    //   cellStyle: {
    //     width: "16%",
    //     minWidth: "100px",
    //     textAlign: "right",
    //   },
    // },
  ];

  const waitingCampaignsColumns = [
    {
      title: "",
      field: "runid",
      cellStyle: {
        width: "50px",
        textAlign: "center",
      },
      render: (row: any) => {
        return (
          <IonIcon
            size="large"
            icon={pencil}
            slot="end"
            onClick={() => {
              console.log(row);
              setShowCreateCampaignModal(true);
              setCampaignEditId(row.runid);
              setCampaignEditRow(row);
              setSelectedProductId(row.productId);
              setMainColor(row.mainColor);
              setAccentColor(row.accentColor);
              setScheduled(row.scheduled);
              setSelectedChannel(row.channelType);
              //todo-location
            }}
            style={{ cursor: "pointer" }}
          ></IonIcon>
        );
      },
      filtering: false,
    },
    {
      title: "שם קמפיין",
      field: "campaignName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "סטטוס",
      field: "complete_status",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
      render: (row: any) => {
        switch (row.complete_status) {
          case 0:
            return <>בקשה ממתינה</>;
          case 2:
            return <>בקשה נכשלה</>;
          default:
            return <>לא נשלחה בקשה</>;
        }
      },
    },
    {
      title: "שגיאה",
      field: "error_desc",
      cellStyle: {
        width: "50px",
        textAlign: "right",
      },
      render: (row: any) => {
        if (row.error_desc != null) {
          return (
            <IonIcon
              size="large"
              icon={addCircle}
              slot="end"
              onClick={() => {
                showRequestErrorHandler(row.runid);
              }}
              style={{ cursor: "pointer" }}
            ></IonIcon>
          );
        }
      },
      filtering: false,
    },
    {
      title: "",
      field: "request_id",
      cellStyle: {
        width: "16%",
        minWidth: "70px",
        textAlign: "right",
      },
      render: (row: any) => {
        //no button on complete_status 0- waiting request
        if (row.complete_status == 2)
          return (
            <button
              style={{
                width: "100%",
                height: "30px",
                fontWeight: "bold",
                borderRadius: "10px",
                backgroundColor: row.complete_status == 2 ? "red" : "green",
              }}
              onClick={() => {
                uploadCampaign(row);
              }}
            >
              {row.complete_status == 2 ? "שלח שוב" : "שלח בקשה"}
            </button>
          );
      },
    },
    // ,r.
  ];

  //#region functions
  const refresh = () => {
    const runId = id == undefined ? "-1" : id;
    setIsCampaignsLoader(true);
    setIsWaitingCampaignsLoader(true);

    getCampaigns(
      runId,
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate),
      authValues?.OrgId!
    ).then((result) => {
      setCampaigns([]);
      setCampaigns(result);
      setIsCampaignsLoader(false);
    });

    getWaitingCampaigns(runId, authValues?.OrgId!).then((result) => {
      setWaitingCampaigns([]);
      setWaitingCampaigns(result);
      setIsWaitingCampaignsLoader(false);
    });
  };

  const changeStatusCampaign = (selectedRow: any) => {
    console.log(selectedRow);
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    const status = selectedRow.status;
    const type = status == 1 ? 3 : 2; //2-turn on , 3-stop
    insertRequestToChangeStatusCampaign(orgId, selectedRow.runId, type)
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה לשינוי ססטוס נוספה בהצלחה!");
          refresh();
        } else {
          toast.warning("רשומה לשינוי ססטוס לא נוספה, יש לפנות לתמיכה");
        }
      })
      .catch(() => {
        toast.error("שגיאה בשינוי ססטוס!!!");
      });
  };

  const uploadCampaign = (selectedRow: any) => {
    console.log(selectedRow);
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    const status = selectedRow.complete_status;
    if (status === 2) {
      //בקשה נכשלה- שליחה חוזרת
      updateRequestToChangeStatusCampaign(selectedRow.request_id)
        .then((result) => {
          if (result === "Success") {
            toast.info("בקשה נשלחה שוב בהצלחה!");
            refresh();
          } else {
            toast.warning("בקשה לא עודכנה, יש לפנות לתמיכה");
          }
        })
        .catch(() => {
          toast.error("שגיאה בעדכון בקשה!!!");
        });
    } else {
      //שליחת בקשה חדשה
      insertRequestToChangeStatusCampaign(orgId, selectedRow.runid, 1)
        .then((result) => {
          if (result === "Success") {
            toast.info("בקשה נשלחה בהצלחה!");
            refresh();
          } else {
            toast.warning("בקשה לא נשלחה, יש לפנות לתמיכה");
          }
        })
        .catch(() => {
          toast.error("שגיאה בשליחת בקשה!!!");
        });
    }
    refresh();
  };

  const createHandler = async (data: any) => {
    console.log(data);
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    //return;
    if (data && data.name && data.finalUrl) {
      addCampaign(
        orgId,
        selectedProduct.accountId,
        selectedProduct.runid,
        data.name,
        scheduled,
        selectedChannel,
        data.category,
        data.location,
        data.title,
        data.longTitle,
        data.description,
        data.price,
        decodeURI(data.finalUrl).replaceAll(" ", "_"),
        data.squareImage,
        data.marketingImage,
        data.logoImage,
        accentColor,
        mainColor
      )
        .then((result) => {
          if (result !== 0) {
            //שליחת בקשה חדשה
            insertRequestToChangeStatusCampaign(
              orgId,
              typeof result === "number" ? result.toString() : "0",
              1
            )
              .then((result) => {
                if (result === "Success") {
                  toast.info("קמפיין נוסף בהצלחה");
                  setSelectedProduct("");
                  setSelectedProductId("");
                  setShowCreateCampaignModal(false);
                  refresh();
                } else {
                  toast.warning("בקשה לא נשלחה, יש לפנות לתמיכה");
                }
              })
              .catch(() => {
                toast.error("שגיאה בשליחת בקשה!!!");
              });
          } else {
            toast.warning("קמפיין לא נוצר במערכת, יש לפנות לתמיכה");
          }
        })
        .catch(() => {
          toast.error("שגיאה ביצירת קמפיין!!!");
        });
    } else {
      toast.warning("לא ניתן להקים קמפיין, ישנם שדות חובה לא מלאים.");
      return;
    }
  };
  const editHandler = async (data: any) => {
    console.log(data);
    //return;
    if (data && data.name && data.finalUrl) {
      updateCampaign(
        campaignEditId,
        data.name,
        scheduled,
        selectedChannel,
        data.category,
        data.location,
        data.title,
        data.longTitle,
        data.description,
        data.price,
        decodeURI(data.finalUrl).replaceAll(" ", "_"),
        data.squareImage,
        data.marketingImage,
        data.logoImage,
        accentColor,
        mainColor
      )
        .then((result) => {
          if (result === "Success") {
            toast.info("רשומה עודכנה בהצלחה");
            refresh();
            setShowCreateCampaignModal(false);
            setCampaignEditId("");
          } else {
            toast.warning("לא נמצאו רשומות לעדכון");
          }
        })
        .catch(() => {
          toast.error("שגיאה בעדכון קמפיין!!!");
        });
    } else {
      toast.warning("לא ניתן לעדכן קמפיין, ישנם שדות חובה לא מלאים.");
      return;
    }
  };

  const fetchLocationsAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      getLocations(prefix).then((res: any) => {
        if (res && res.length) {
          resolve(res);
        } else {
          resolve([]);
        }
      });
    });
  };
  const changeCampaignDeleted = (selectedRow: any) => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    console.log(selectedRow);

    checkIfHasRequestToCampaign(selectedRow.runId, 4)
      .then((result) => {
        if (result == 0) {
          insertRequestToChangeStatusCampaign(orgId, selectedRow.runId, 4)
            .then((result) => {
              if (result === "Success") {
                toast.info("רשומה למחיקת קמפיין נוספה בהצלחה!");
                refresh();
              } else {
                toast.warning("רשומה למחיקת קמפיין לא נוספה, יש לפנות לתמיכה");
              }
            })
            .catch(() => {
              toast.error("שגיאה במחיקת קמפיין!!!");
            });
        } else {
          toast.error("ישנה בקשה ממתינה למחיקת מוצר.");
        }
      })
      .catch(() => {
        toast.error("שגיאה במחיקת מוצר!");
      });
  };

  const showRequestErrorHandler = async (runid: number) => {
    getApplicationError("campaign", runid.toString())
      .then((result: any) => {
        setErrorRequest(result);
        setShowError(true);
      })
      .catch(() => {
        toast.error("שגיאה בהצגת שגיאות!");
      });
  };
  //#endregion

  useEffect(() => {
    //refresh();
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getProducts(orgId).then((result) => {
      setProducts(result);
    });
  }, []);

  useEffect(() => {
    if (cookies.get("selectedStartDate") && cookies.get("selectedEndDate")) {
      const fromDate: Date = moment(cookies.get("selectedStartDate")).toDate();
      const endDate: Date = moment(cookies.get("selectedEndDate")).toDate();
      setSelectedStartDate(fromDate);
      setSelectedEndDate(endDate);
    } else {
      if (
        VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
        VendorDetailsCxt.GeneralInfo?.SelectedToDate
      ) {
        setSelectedStartDate(VendorDetailsCxt.GeneralInfo?.SelectedFromDate);
        setSelectedEndDate(VendorDetailsCxt.GeneralInfo?.SelectedToDate);
      }
    }
  }, [
    VendorDetailsCxt.GeneralInfo?.SelectedFromDate,
    VendorDetailsCxt.GeneralInfo?.SelectedToDate,
  ]);

  useEffect(() => {
    refresh();
    cookies.set("selectedStartDate", selectedStartDate);
    cookies.set("selectedEndDate", selectedEndDate);
    if (id != undefined) {
      getPeriodData(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        authValues?.OrgId!,
        id
      ).then((result) => {
        setPeriodData(result);
      });
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    //debugger;

    var costT = 0.0;
    var clicksT = 0;
    var impressionsT = 0;
    var conversionsT = 0.0;

    if (chartDataOrg?.length > 1) {
      for (var i = 1; i < chartDataOrg.length; i++) {
        var obj = chartDataOrg[i];
        costT = costT + parseFloat(obj[1]);
        clicksT = clicksT + parseInt(obj[2]);
        impressionsT = impressionsT + parseInt(obj[3]);
        conversionsT = conversionsT + parseFloat(obj[4]);
      }
    }
    setCost(costT);
    setClicks(clicksT);
    setImpressions(impressionsT);
    setConversions(conversionsT);

    setShowCost(true);
    setShowImpressions(true);
    setShowConversions(true);
    setShowClicks(true);
  }, [chartDataOrg]);

  const chartOptions = {
    title: "",
    curveType: "",
    legend: { position: "bottom" },
    series: {
      0: { color: "#e2431e" },
      1: { color: "#e7711b" },
      2: { color: "#f1ca3a" },
      3: { color: "#6f9654" },
    },
  };

  useEffect(() => {
    //debugger;
    // setChartData(chartDataOrg);
    //["", "cost", "clicks", "impressions", "conversions"]
    if (campaignGraphShow && chartDataOrg?.length > 1) {
      if (chartDataOrg?.length > 1) {
        var arr = chartDataOrg;
        if (!showConversions) {
          if (!(!showImpressions && !showClicks && !showCost)) {
            arr = removeEl(arr, 4);
          }
        }
        if (!showImpressions) {
          if (!(!showConversions && !showClicks && !showCost)) {
            arr = removeEl(arr, 3);
          }
        }

        if (!showClicks) {
          if (!(!showConversions && !showImpressions && !showCost)) {
            arr = removeEl(arr, 2);
          }
        }
        if (!showCost) {
          if (!(!showConversions && !showImpressions && !showClicks)) {
            arr = removeEl(arr, 1);
          }
        }

        setChartData(arr);
      }
    }

    function removeEl(array: any[], remIdx: any) {
      return array.map(function (arr: any[]) {
        return arr.filter(function (el: any, idx: any) {
          return idx !== remIdx;
        });
      });
    }
  }, [showCost, showClicks, showConversions, showImpressions]);

  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Campaigns"}
      title="קמפיינים"
    >
      <IonGrid>
        {/* <IonCard> */}
        <IonRow className="ion-no-margin ion-no-padding">
          <IonCol
            className="ion-no-margin ion-no-padding"
            style={{ display: "flex" }}
          >
            <h2 className="card-title large-title">סיכום נתונים תקופתי</h2>
            <IonButton
              size="small"
              className="date-range-picker-btn"
              onClick={() => {
                setDateRangeShow(true);
              }}
            >
              <IonIcon size="small" icon={calendarOutline} slot="end" />
              <IonLabel>
                {getDateFormat(selectedEndDate)} -
                {getDateFormat(selectedStartDate)}
              </IonLabel>
            </IonButton>
            <IonButton
              size="small"
              onClick={() => {
                setShowCreateCampaignModal(true);
                setCampaignEditId("");
              }}
              style={{
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "16px",
              }}
            >
              <IonIcon size="small" icon={create} slot="start" />
              יצירת קמפיין
            </IonButton>
          </IonCol>
        </IonRow>

        {id != undefined && (
          <IonCard style={{ marginTop: "10px" }}>
            <IonRow className="ion-no-margin ion-no-padding">
              <IonCol
                className="ion-no-margin ion-no-padding"
                style={{ display: "flex" }}
              >
                <h2 className="card-title large-title">אז איך הולך?</h2>
              </IonCol>
            </IonRow>

            {periodData && (
              <ul className="summery-list">
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#cbe3ff" }}
                >
                  <div className="extra-large-title">
                    {periodData.activeCampaign
                      ? periodData.activeCampaign
                      : "-"}
                  </div>
                  <div>קמפיינים פעילים כרגע</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <div className="extra-large-title">
                    {periodData.spendAmount
                      ? periodData.spendAmount.toLocaleString()
                      : "-"}
                  </div>
                  <div>הסכום שנוצל</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#d5edf9" }}
                >
                  <div className="extra-large-title">
                    {periodData.costConv ? periodData.costConv : "-"}
                  </div>
                  <div>עלות ממוצעת להמרה</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#c4d9e6" }}
                >
                  <div className="extra-large-title">
                    {periodData.conversions ? periodData.conversions : "-"}
                  </div>
                  <div>המרות</div>
                </li>

                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <div className="extra-large-title">
                    {periodData.clicks
                      ? periodData.clicks.toLocaleString()
                      : "-"}
                  </div>
                  <div>קליקים</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#d5edf9" }}
                >
                  <div className="extra-large-title">
                    {periodData.impressions
                      ? periodData.impressions.toLocaleString()
                      : "-"}
                  </div>
                  <div>חשיפות</div>
                </li>
              </ul>
            )}
          </IonCard>
        )}
        {isWaitingCampaignsLoader ? (
          <IonSpinner></IonSpinner>
        ) : (
          <div
            style={{ display: waitingCampaigns.length > 0 ? "block" : "none" }}
          >
            <IonRow>קמפיינים ממתינים לעלייה: {waitingCampaigns.length}</IonRow>{" "}
            <CustomMaterialTable
              rows={waitingCampaigns}
              columns={waitingCampaignsColumns}
              title={"קמפיינים ממתינים לעלייה"}
              loaderDuration={3000}
              isDateRange={false}
              isEditable={false}
              isFilterable={false}
              pageSize={5}
            />
          </div>
        )}
        {isCampaignsLoader ? (
          <IonSpinner></IonSpinner>
        ) : (
          <>
            {" "}
            <IonRow>סה"כ קמפיינים: {campaigns.length}</IonRow>{" "}
            <CustomMaterialTable
              rows={campaigns}
              columns={columns}
              title={"קמפיינים"}
              loaderDuration={3000}
              isDateRange={false}
              isEditable={false}
              isFilterable={false}
              pageSize={campaigns.length}
              className="campaignsGrid"
            />
          </>
        )}

        {/* </IonCard> */}
      </IonGrid>
      <CustomModal
        pageName={"DateRangePicker"}
        headerParameters={headerParameters}
        closeModal={() => {
          setDateRangeShow(false);
        }}
        isOpen={isDateRangeShow}
        title="בחר טווח תאריכים"
      >
        <CustomDateRangePicker
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setDateRangeShow(false);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            if (campaignGraphShow) {
              getChartData(
                getDateReverseFormat(startDate),
                getDateReverseFormat(endDate),
                selectedCampaign.campaign_id
              ).then((res) => {
                //console.log(res);
                //debugger;
                setChartData(res);
                setChartDataOrg(res);
              });
            }
          }}
        ></CustomDateRangePicker>
      </CustomModal>
      <CustomModal
        pageName="CampaignGraph"
        headerParameters={headerParameters}
        closeModal={() => {
          setCampaignGraphShow(false);
        }}
        isOpen={campaignGraphShow}
        title={
          "נתוני קמפיין " +
          selectedCampaign.campaignName +
          ", מס' קמפיין: " +
          selectedCampaign.campaign_id
        }
      >
        <IonGrid>
          <IonRow>
            <IonButton
              size="small"
              className="date-range-picker-btn"
              onClick={() => {
                setDateRangeShow(true);
              }}
            >
              <IonIcon size="small" icon={calendarOutline} slot="end" />
              <IonLabel>
                טווח תאריכים:
                {getDateFormat(selectedEndDate)} -
                {getDateFormat(selectedStartDate)}
              </IonLabel>
            </IonButton>
          </IonRow>
          <IonRow>
            <IonCol>
              <ul className="summery-list">
                <li
                  className="summery-list-item"
                  style={{
                    backgroundColor: showCost ? "#cbe3ff" : "#eaedff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      !(!showImpressions && !showConversions && !showClicks)
                    ) {
                      setShowCost(showCost ? false : true);
                    }
                  }}
                >
                  <div className="small-title">
                    cost <div>{cost.toFixed(2).toLocaleString()}</div>
                  </div>
                </li>
                <li
                  className="summery-list-item"
                  style={{
                    backgroundColor: showImpressions ? "#cbe3ff" : "#eaedff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!(!showCost && !showConversions && !showClicks)) {
                      setShowImpressions(showImpressions ? false : true);
                    }
                  }}
                >
                  <div className="small-title">
                    Impressions <div>{impressions.toLocaleString()}</div>
                  </div>
                </li>
                <li
                  className="summery-list-item"
                  style={{
                    backgroundColor: showConversions ? "#cbe3ff" : "#eaedff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!(!showCost && !showImpressions && !showClicks)) {
                      setShowConversions(showConversions ? false : true);
                    }
                  }}
                >
                  <div className="small-title">
                    Conversions <div>{conversions.toLocaleString()}</div>
                  </div>
                </li>
                <li
                  className="summery-list-item"
                  style={{
                    backgroundColor: showClicks ? "#cbe3ff" : "#eaedff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!(!showCost && !showImpressions && !showConversions)) {
                      setShowClicks(showClicks ? false : true);
                    }
                  }}
                >
                  <div className="small-title">
                    Clicks <div>{clicks.toLocaleString()}</div>
                  </div>
                </li>
              </ul>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="graph-container">
              {campaignGraphShow && chartData?.length > 1 ? (
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height={!isMobile ? 300 : 250}
                  data={chartData}
                  options={chartOptions}
                  loader={<div>גרף בטעינה</div>}
                />
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomModal>
      <CustomModal
        pageName="createCampaign"
        headerParameters={headerParameters}
        closeModal={() => {
          setShowCreateCampaignModal(false);
          setCampaignEditId("");
        }}
        isOpen={showCreateCampaignModal}
        title={campaignEditId == "" ? "יצירת קמפיין" : "עריכת קמפיין"}
      >
        <CustomForm
          onSubmit={handleSubmit(
            campaignEditId == "" ? createHandler : editHandler
          )}
          errors={errors}
        >
          <CustomAlert
            isOpen={showAlert}
            header={"הודעת מערכת"}
            message={error}
            onDismiss={() => {
              setShowAlert(false);
              setError("");
            }}
            buttons={ErrorMessageButton}
          ></CustomAlert>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem mode="md" color={"light"} lines="none">
                  <IonLabel slot="start">מוצר</IonLabel>
                  <IonSelect
                    slot="start"
                    defaultValue={-1}
                    placeholder="בחר מוצר"
                    onIonChange={(e) => {
                      setSelectedProductId(e.detail.value);
                      const prod = products.find(
                        (x: any) => x.runid === e.detail.value
                      );
                      console.log(prod);
                      setSelectedProduct(null);
                      if (prod != undefined) {
                        setSelectedProduct(prod);
                        setAccentColor(
                          prod.accentColor ? prod.accentColor : ""
                        );
                        setMainColor(prod.mainColor ? prod.mainColor : "");
                      }
                    }}
                    value={selectedProductId}
                    interface="action-sheet"
                    style={{ minWidth: "75%" }}
                    name="productId"
                    disabled={campaignEditId != ""}
                  >
                    {products &&
                      products.map((product: any) => {
                        return (
                          <IonSelectOption
                            key={product.runid}
                            value={product.runid}
                          >
                            {product.name}
                          </IonSelectOption>
                        );
                      })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            {(selectedProduct || campaignEditId != "") && (
              <>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="name"
                      label="שם"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.campaignName
                          : selectedProduct.name
                      }
                      required={true}
                      maxlength={300}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomToggle
                      name="scheduled"
                      titleTxt="מתוזמן"
                      checked={scheduled == 1}
                      onIonChange={(e: any) => {
                        setScheduled(e.detail.checked === true ? 1 : 0);
                      }}
                    />
                  </IonCol>
                  <IonCol>
                    <CustomInput
                      name="price"
                      label="מחיר"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.price
                          : selectedProduct.price
                      }
                      maxlength={50}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem
                      mode="md"
                      color={"light"}
                      lines="none"
                      style={{ marginLeft: "10px" }}
                    >
                      <IonLabel slot="start">סוג ערוץ</IonLabel>
                      <IonSelect
                        value={selectedChannel}
                        name="channel"
                        defaultValue={-1}
                        onIonChange={(e) => {
                          setSelectedChannel(e.detail.value);
                        }}
                        slot="start"
                      >
                        <IonSelectOption value={-1} key={-1}>
                          בחר
                        </IonSelectOption>
                        <IonSelectOption value={1} key={1}>
                          גוגל
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <CustomInput
                      name="category"
                      label="קטגוריה"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.category
                          : selectedProduct.category
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomAutocomplete
                      name="location"
                      label="מיקום"
                      register={register}
                      control={control}
                      errors={errors}
                      minInputLength={1}
                      clearInputAfterSelect={false}
                      getAutoCompleteItems={fetchLocationsAutocomplete}
                      maxResults={6}
                    />
                  </IonCol>
                  <IonCol>
                    <CustomInput
                      name="title"
                      label="כותרת"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.title
                          : selectedProduct.title
                      }
                      maxlength={500}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="longTitle"
                      label="כותרת ארוכה"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.longTitle
                          : selectedProduct.longTitle
                      }
                      maxlength={500}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="description"
                      label="תיאור"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.description
                          : selectedProduct.description
                      }
                      maxlength={500}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="finalUrl"
                      label="Url"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.finalUrl
                          : selectedProduct.finalUrl
                      }
                      required={true}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="squareImage"
                      label="squareImage"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.squareImage
                          : selectedProduct.squareImage
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="marketingImage"
                      label="marketingImage"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.marketingImage
                          : selectedProduct.marketingImage
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      name="logoImage"
                      label="logoImage"
                      errors={errors}
                      control={control}
                      type="text"
                      value={
                        campaignEditId != ""
                          ? campaignEditRow.logoImage
                          : selectedProduct.logoImage
                      }
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel style={{ marginLeft: "10px" }}>
                      accent color:
                    </IonLabel>
                    <input
                      type="color"
                      value={accentColor}
                      name="accentColor"
                      onChange={(e: any) => {
                        setAccentColor(e.target.value);
                      }}
                    ></input>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ marginLeft: "10px" }}>
                      main color:
                    </IonLabel>
                    <input
                      type="color"
                      value={mainColor}
                      name="accentColor"
                      onChange={(e: any) => {
                        setMainColor(e.target.value);
                      }}
                    ></input>
                  </IonCol>
                </IonRow>
              </>
            )}
            <IonRow className="actions-wrapper" style={{ position: "unset" }}>
              <IonCol>
                <CustomPublishButton
                  expand="block"
                  className="main-button"
                  title={campaignEditId == "" ? "צור קמפיין" : "שמור קמפיין"}
                  disabled={selectedProductId == ""}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </CustomForm>
      </CustomModal>
      <CustomModal
        pageName="errorCampaign"
        isOpen={showError}
        title={"שגיאת מנוע"}
        closeModal={() => {
          setShowError(false);
          setErrorRequest("");
        }}
        headerParameters={headerParameters}
        // buttons={ErrorMessageButton}
      >
        <div style={{ textAlign: "left" }}>{errorRequest}</div>
      </CustomModal>
    </CustomPage>
  );
};

export default Campaigns;
