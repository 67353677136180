import { isPlatform } from "@ionic/react";
import jsPDF from "jspdf";
import React from "react";
import CustomAddFloatingButton from "../CustomAddFloatingButton";
import CustomeMoreOptions, { optionButtons } from "../CustomeMoreOptions";
import { floatingButton } from "../CustomPage";
import CustomPdfButton from "../CustomPdfButton";
import "./customPageTitle.scss";
import { isMobile } from "react-device-detect";

const CustomPageTitle: React.FC<{
  title?: string;
  subTitle?: string;
  titleClass?: string;
  subTitleClass?: string;
  pageName?: string;
  floatingButton?: floatingButton;
  pdfDownloadPdf?: jsPDF | undefined;
  moreOptionsButtons?: optionButtons[] | undefined;
  isBreadCrumbs?: boolean;
  isLogin?: boolean;
}> = (props) => {
  const pageTitle = () => {
    return (
      <>
        <div className={"page-title-wraper " + (props.isLogin ? "login" : "")}>
          {props.pageName === "Home" ? (
            <h2 className={`white-text ion-no-margin xl-p ${props.titleClass}`}>
              {props.title}
            </h2>
          ) : (
            <h1
              className={
                props.isBreadCrumbs
                  ? "ion-no-margin breadCrumbs-title"
                  : "ion-no-margin"
              }
            >
              {props.title}
            </h1>
          )}
          {props.subTitle && props.subTitle !== "" ? (
            <h1 className={props.subTitleClass}>{props.subTitle}</h1>
          ) : null}
        </div>
        {props.floatingButton !== undefined ? (
          <CustomAddFloatingButton
            onclick={() => {
              props.floatingButton?.onClick();
            }}
            title={props.floatingButton?.title}
            icon={props.floatingButton?.icon}
          ></CustomAddFloatingButton>
        ) : (
          ""
        )}
        {!isMobile && props.pdfDownloadPdf && (
          <CustomPdfButton pdf={props.pdfDownloadPdf}></CustomPdfButton>
        )}
        {!isMobile &&
          props.moreOptionsButtons &&
          props.moreOptionsButtons.length > 0 && (
            <CustomeMoreOptions
              options={props.moreOptionsButtons}
            ></CustomeMoreOptions>
          )}
      </>
    );
  };
  return pageTitle();
};

export default CustomPageTitle;
