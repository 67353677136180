import React, { ComponentProps, FC, useRef } from "react";

import { IonCol, IonGrid, IonItem, IonRow, IonToggle } from "@ionic/react";

type IonToggleProps = ComponentProps<typeof IonToggle>;

export interface titleOptions {
  onTrue: string;
  onFalse: string;
}

interface ToggleProps extends IonToggleProps {
  modeTitleOptions?: titleOptions;
  titleTxt: string;
  subTitleTxt?: string;
  lines?: any;
  toggleTitle?: boolean;
}

const CustomToggle: FC<ToggleProps> = ({
  modeTitleOptions,
  titleTxt,
  subTitleTxt,
  lines,
  toggleTitle,
  ...props
}) => {
  const paragraphElementRef = useRef<HTMLParagraphElement>(null);

  let modeTitle: string | undefined;

  if (modeTitleOptions !== undefined && props.checked !== undefined) {
    modeTitle = props.checked
      ? modeTitleOptions.onTrue
      : modeTitleOptions.onFalse;
  }

  return (
    <IonItem
      disabled={props.disabled ? props.disabled : false}
      lines={lines ? lines : "none"}
      className="ion-no-padding toggle-item"
      mode="md"
    >
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol>
            {titleTxt && (
              <p className={!toggleTitle ? "small-p bold-text" : "bold-text"}>
                {titleTxt}
              </p>
            )}
            {subTitleTxt && <p>{subTitleTxt}</p>}
          </IonCol>
          <IonCol className="toggle-wrapper">
            {modeTitle !== undefined && (
              <IonCol className="text-align-left">
                <p ref={paragraphElementRef} className="small-p">
                  {modeTitle}
                </p>
              </IonCol>
            )}

            <IonToggle
              mode="md"
              {...props}
              onIonChange={(e) => {
                if (paragraphElementRef.current && modeTitleOptions) {
                  paragraphElementRef.current!.innerText = e.detail.checked
                    ? modeTitleOptions.onTrue
                    : modeTitleOptions.onFalse;
                }
                if (props.onIonChange) {
                  props.onIonChange(e);
                }
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default CustomToggle;
