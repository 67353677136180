import React, { useContext, useEffect, useState } from "react";
import { getVendorHamlatza } from "../services/VendorDetailsService";
// import CustomDataGrid from "./utils/CustomElements/CustomDataGrid/CustomDataGrid";
// import { GridColDef } from "@material-ui/data-grid";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import { IonCheckbox, IonGrid } from "@ionic/react";
import VendorDetailsContext from "../data/VendorDetails-context";
import { defaultStaticRanges } from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { HeaderParameters } from "../data/Header-Context";
import CustomPage from "./utils/CustomElements/CustomPage";

const ActivityByDate: React.FC = () => {
  const VendorDetailsCxt = useContext(VendorDetailsContext);

  const [VendorHamltza, setVendorHamltza] = useState<any>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();

  const [showDateRangePicker, setShowDateRangePicker] =
    useState<boolean>(false);
  const [sums, setSums] = useState<any>([]);

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  useEffect(() => {
    if (VendorDetailsCxt.GeneralInfo == null) {
      VendorDetailsCxt.initVendorDetailsContext();
    }
  }, []);

  useEffect(() => {
    if (
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod &&
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod !== "-1"
    ) {
      setSelectedStartDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .startDate
      );
      setSelectedEndDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .endDate
      );
    } else if (
      VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
      VendorDetailsCxt.GeneralInfo?.SelectedToDate
    ) {
      setSelectedStartDate(VendorDetailsCxt.GeneralInfo?.SelectedFromDate);
      setSelectedEndDate(VendorDetailsCxt.GeneralInfo?.SelectedToDate);
    }
  }, [VendorDetailsCxt.GeneralInfo?.DefaultPeriod]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      getVendorHamlatza(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate)
      ).then((result) => {
        setVendorHamltza(result);
      });
    }
  }, [selectedEndDate]);

  const columns = [
    {
      field: "cat_desc",
      title: "קטגוריה",
      // editable: true,
      cellStyle: {
        width: "16%",
      },
    },
    {
      field: "area_desc",
      title: "אזור",
      // editable: true,
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "total_members_in_area",
      title: "סך לקוחות באזור",
      type: "numeric",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "ppi_members_in_area",
      title: "לקוחות PPI",
      type: "numeric",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "calculated_monthly_budget",
      title: "תקציב חודשי מחושב",
      type: "numeric",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "clicks_recommended_by_pct",
      title: "המלצה לפרסום",
      type: "numeric",
      cellStyle: {
        width: "12%",
      },
    },
    {
      field: "is_marked",
      title: "עצור המלצה",
      sortable: false,
      cellStyle: {
        width: "12%",
      },
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <IonCheckbox
            disabled={true}
            checked={params.row.is_marked}
          ></IonCheckbox>
        );
      },
    },
  ];

  return (
    <CustomPage headerParameters={headerProps} pageName={"Home"}>
      <IonGrid>
        {VendorDetailsCxt &&
          VendorDetailsCxt.GeneralInfo != null &&
          selectedStartDate &&
          selectedEndDate && (
            <CustomMaterialTable
              rows={VendorHamltza}
              columns={columns}
              summaryTbl={sums}
              title="המלצה לפרסום"
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              isChangDefualteDate={true}
              onSearchClick={(startDate: Date, endDate: Date) => {
                setSelectedStartDate && setSelectedStartDate(startDate);
                setSelectedEndDate && setSelectedEndDate(endDate);
                setShowDateRangePicker(false);
                getVendorHamlatza(
                  getDateReverseFormat(startDate),
                  getDateReverseFormat(endDate)
                ).then((result) => {
                  setVendorHamltza(result);
                });
              }}
              loaderDuration={20000}
            />
          )}
      </IonGrid>
    </CustomPage>
  );
};

export default ActivityByDate;
