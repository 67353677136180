import React from "react";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

export const IsNavigatorOnLine = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);
  
  Storage.set({
    key: "navigatorOnLine",
    value: JSON.stringify(status),
  });
  return status;
};

export const GetIsNavigatorOnLine = async () => {
  const navigatorOnLine = await Storage.get({ key: "navigatorOnLine" });
  return navigatorOnLine.value
    ? JSON.parse(navigatorOnLine.value)
    : true;
    
};  
