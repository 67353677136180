import React, { FC, ComponentProps, useState } from "react";
import { download } from "../../../images/index";
import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { FilesystemDirectory, Plugins } from "@capacitor/core";
import { toast } from "react-toastify";
import CustomLoader from "./CustomLoader";
import { isMobile } from "react-device-detect";

type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  pdf: jsPDF;
  icon?: string;
}

const CustomPdfButton: FC<ButtonProps> = ({ pdf, ...props }) => {
  const [isShowLoader, setIsShowLoader] = useState(false);
  const generatePDF = () => {
    setIsShowLoader(true);
    if (pdf) {
      if (isPlatform("mobileweb") || !isMobile) {
        pdf.save(`${new Date().toISOString()}.pdf`);
        setIsShowLoader(false);
      } else {
        let blob = pdf.output("blob");
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data: any = reader!.result;
          Plugins.Filesystem.appendFile({
            data: base64data,
            path: `${new Date().toISOString()}.pdf`,
            directory: FilesystemDirectory.Documents,
          }).then((c) => {
            setIsShowLoader(false);
            toast("קובץ נשמר בהצלחה בתיקיית מסמכים");
          });
          setIsShowLoader(false);
        };
      }
    }
  };

  // var printWindow = window.open("", "", "height=400,width=800");
  // printWindow!.document.write("<html><head><title></title>");
  // printWindow!.document.write("</head><body >");
  // printWindow!.document.write(elementHTML);
  // printWindow!.document.write("</body></html>");
  // printWindow!.document.close();
  // printWindow!.print();
  // printWindow!.close();
  /* pdf.html(span, {
        callback: function (pdf) {
          pdf.save();
        }
     }); */

  /* pdf.setFont(vfs["Roboto-Regular.ttf"]); // set custom font
        pdf.html("<div>גגג</div>").then(() => {
          pdf.save(`${new Date().toISOString()}.pdf`);
        }); */

  // domtoimage.toPng(domElement, { quality: 0.9 }).then((imgData: any) => {
  //   pdf.addImage(imgData, "PNG", left, top, width, height);
  //   if (isPlatform("mobileweb") || !isMobile) {
  //     pdf.save(`${new Date().toISOString()}.pdf`);
  //     setIsShowLoader(false);
  //   } else {
  //     const path = `${new Date().toISOString()}.png`;
  //     Plugins.Filesystem.appendFile({
  //       data: imgData,
  //       path: path,
  //       directory: FilesystemDirectory.Documents,
  //     }).then((c) => {
  //       setIsShowLoader(false);
  //       toast("קובץ נשמר בהצלחה בתיקיית מסמכים");
  //     });
  //   }
  // });

  return (
    <div>
      <IonButton
        className="button-flex-rows"
        fill="clear"
        onClick={() => {
          generatePDF();
        }}
        {...props}
      >
        <div>
          <IonIcon icon={props.icon ? props.icon : download} />
          {!isMobile && <p>הורדת דו"ח</p>}
        </div>
      </IonButton>
      <CustomLoader isOpen={isShowLoader}></CustomLoader>
    </div>
  );
};

export default CustomPdfButton;
