import { fetchRequest } from "../utils-ts/fetchRequest";
import { config } from "../Constants";

export const getUsers = (isAdmin: boolean, orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      isAdmin: isAdmin.toString(),
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/user/getUsers`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getOrgs = () => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(`${config.url.API_URL}/user/getOrgs`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const updateUser = (
  userId: string,
  id: string,
  contactName: string,
  phone: string,
  email: string,
  userName: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      userId: userId,
      id: id,
      contactName: contactName,
      phone: phone,
      email: email,
      userName: userName,
    }).toString();
    fetchRequest(`${config.url.API_URL}/user/updateUser`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateUser failed!!!");
      });
  });
};
export const updateUserOrgId = (userId: string, id: string, orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      userId: userId,
      id: id,
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/user/updateUserOrgId`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateUserOrgId failed!!!");
      });
  });
};

export const addUser = (
  userId: string,
  contactName: string,
  phone: string,
  email: string,
  userName: string,
  isAdmin: boolean,
  orgId: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      userId: userId,
      contactName: contactName,
      phone: phone,
      email: email,
      userName: userName,
      isAdmin: isAdmin == true ? "1" : "0",
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/user/addUser`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("addUser failed!!!");
      });
  });
};
