import { resolveAny } from "node:dns";
import React from "react";

export interface userData {
  Token: string | null;
  Email: string | null;
  Id: number | null;
  Name: string | null;
  FirebaseToken: string | null;
  IsAdmin: boolean | null;
  OrgId: string | null;
}

const UserContext = React.createContext<{
  authValues: userData | undefined;
  login: (email: string, password: string, firebaseToken: any) => any;
  loginTest: () => any;
  logout: (calbackFunc: () => void) => void;
  changeVendorByAdmin: (
    vendorId: number,
    adminEmail: string,
    adminName: string,
    isAdmin: boolean,
    orgId: string
  ) => Promise<any>;
  initUserContext: () => void;
}>({
  authValues: {
    Token: null,
    Email: null,
    Id: null,
    Name: null,
    IsAdmin: null,
    OrgId: null,
    FirebaseToken: null,
  },
  login: () => {},
  loginTest: () => {},
  logout: () => {},
  changeVendorByAdmin: () => Promise.resolve(),
  initUserContext: () => {},
});

export default UserContext;
