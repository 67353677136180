// import { IonButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsClicksStatisticsByArea } from "../services/VendorDetailsService";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
// import CustomDataGrid from "./utils/CustomElements/CustomDataGrid/CustomDataGrid";
// import { GridColDef } from "@material-ui/data-grid";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import ActivityRaw from "./ActivityRaw";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { isPlatform } from "@ionic/react";
import VendorDetailsContext from "../data/VendorDetails-context";
import EventsDetails from "./EventsDetails";
import { isMobile } from "react-device-detect";

const ActivityByArea: React.FC<{
  show: boolean;
  closeModal: (isDeleted: boolean) => void;
  referer_id: string;
  cat_code: string;
  cat_title: string;
  startDate: Date;
  endDate: Date;
}> = (props) => {
  const [ActivityCatArea, setActivityCatArea] = useState<any>([]);

  const headerParameters: HeaderParameters = {
    modalType: "modal",
    isBreadCrumbs: true,
  };

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.startDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(props.endDate);
  const [selectedCityCode, setSelectedCityCode] = useState("-1");
  const [showActivityRawGrid, setShowActivityRawGrid] = useState(false);
  const title = !isMobile
    ? props.cat_title + " > נתונים לפי קטגוריה ואזור"
    : "נתונים לפי קטגוריה ואזור";
  const [selectedAreaTitle, setSelectedAreaTitle] = useState("");
  const [selectedAreaCode, setSelectedAreaCode] = useState("");
  const [sums, setSums] = useState<any>([]);
  const [showEventsDetailsGrid, setShowEventsDetailsGrid] = useState(false);

  useEffect(() => {
    getVendorsClicksStatisticsByArea(
      props.referer_id,
      props.cat_code,
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate),
      false
    ).then((result) => {
      getVendorsClicksStatisticsByArea(
        props.referer_id,
        props.cat_code,
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        true
      ).then((result) => {
        setSums({ data: result });
      });
      setActivityCatArea(result);
    });
  }, [selectedStartDate, selectedEndDate]);

  const activityRawFunc = (selectedRow: any) => {
    setSelectedCityCode(selectedRow.area_code.toString());
    setSelectedAreaTitle(selectedRow.area_desc);
    setShowActivityRawGrid(true);
  };

  const gridActivities = [
    {
      icon: "query_builder",
      btnId: "hour",
      tooltip: "תנועה גולמית",
      onClick: activityRawFunc,
    },
  ];

  const columns = [
    {
      field: "id",
      title: "מפתח",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "cat_desc",
      title: "קטגוריה",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "area_code",
      title: "קוד אזור",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "area_desc",
      title: "אזור",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "איבנטים",
      field: "tot_events_count",
      type: "numeric",
      cellStyle: {
        cellStyle: {
          width: "8.4%",
        },
      },
      render: (row: any) => {
        const eventsDetailsFunc = () => {
          setSelectedAreaCode(row.area_code);
          setSelectedAreaTitle(row.area_desc);
          setShowEventsDetailsGrid(true);
        };
        return row.tot_events_count > 0 ? (
          <button className="link-button" onClick={eventsDetailsFunc}>
            {row.tot_events_count}
          </button>
        ) : (
          row.tot_events_count
        );
      },
    },
    {
      field: "tot_clicks_count",
      title: "חשיפות",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "clicks_charged",
      title: "חשיפות לחיוב",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "amount_charged",
      title: "הכנסות",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "clicks_above_budget",
      title: "מעל תקציב",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "clicks_frauds",
      type: "numeric",
      title: "הונאה",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      field: "clicks_black_list",
      title: "רשימה שחורה",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
  ];

  return (
    <React.Fragment>
      <CustomModal
        pageName={"TrafficSourcePage"}
        headerParameters={headerParameters}
        title={isMobile ? props.cat_title + " > " + title : ""}
        closeModal={() => {
          props.closeModal(false);
        }}
        isOpen={props.show}
      >
        <CustomMaterialTable
          rows={ActivityCatArea}
          columns={columns}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
          }}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          summaryTbl={sums}
          gridActivities={gridActivities}
          title={title}
          isDateRange={true}
        />
      </CustomModal>
      {showActivityRawGrid && (
        <ActivityRaw
          referer_id={props.referer_id}
          cat_code={props.cat_code}
          city_code={selectedCityCode}
          city_title={props.cat_title + " > " + selectedAreaTitle}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          show={showActivityRawGrid}
          closeModal={(isDeleted: boolean) => {
            setShowActivityRawGrid(false);
          }}
        ></ActivityRaw>
      )}

      {showEventsDetailsGrid && (
        <EventsDetails
          referer_id={props.referer_id}
          cat_code={props.cat_code}
          area_code={selectedAreaCode}
          referer_desc={selectedAreaTitle}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          show={showEventsDetailsGrid}
          closeModal={(isDeleted: boolean) => {
            setShowEventsDetailsGrid(false);
          }}
        ></EventsDetails>
      )}
    </React.Fragment>
  );
};

export default ActivityByArea;
