import React from "react";
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  isPlatform,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
const CustomAddFloatingButton: React.FC<{
  title?: string;
  onclick: () => void;
  icon?: string;
  flippedContent?: boolean;
}> = (props) => {
  return (
    <>
      {!isMobile ? (
        <IonButton
          className="button-pop"
          onClick={() => {
            props.onclick();
          }}
        >
          {props.icon && <IonIcon icon={props.icon} />}
          {props.title}
        </IonButton>
      ) : (
        <IonFab
          vertical="bottom"
          className="ion-padding"
          horizontal="end"
          slot="fixed"
        >
          <IonFabButton
            className="button-pop"
            onClick={() => {
              props.onclick();
            }}
          >
            {props.title}
            {props.icon && <IonIcon icon={props.icon} />}
          </IonFabButton>
        </IonFab>
      )}
    </>
  );
};

export default CustomAddFloatingButton;
