import { fetchRequest } from "../utils-ts/fetchRequest";
import { config } from "../Constants";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";

export const getVendorCard = (fromDate: string, toDate: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/vendorCard`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorSettings = (type: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      type: type,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/settings/getVendorSetting`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        var result = res && res.length > 0;
        if (result) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getActiveVendorDateSetting = () => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/settings/getActiveVendorDateSetting`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const updateVendorSetting = (
  id: string,
  type: string,
  value: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      id: id,
      type: type,
      value: value,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/settings/updateVendorSetting`,
      "post",
      data
    )
      .then((response: any) => {
        resolve(response);
      })
      .catch(function (error) {
        resolve("updateVendorSetting Failed!!");
      });
  });
};

export const getChartData = (period: string, type: string) => {
  // period:
  //  1 -> last year
  //  2 -> last month
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      period: period,
      type: type,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/chartData`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsMain = (fromDate: string, toDate: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/activityByRef`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsClicksStatistics = (
  referer_id: string,
  fromDate: string,
  toDate: string,
  is_sums: boolean
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      fromdate: fromDate,
      todate: toDate,
      is_sums: is_sums.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/vendorsClicksStatistics`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsClicksStatisticsByHour = (
  referer_id: string,
  forDate: string,
  isSums: boolean
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      fordate: forDate,
      is_sums: isSums.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/vendorsClicksStatisticsByHour`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsClicksStatisticsCatByHour = (
  referer_id: string,
  cat_code: string,
  fromDate: string,
  isSums: boolean
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      cat_code: cat_code,
      fordate: fromDate,
      is_sums: isSums.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/getVendorsClicksStatisticsCatByHour`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsClicksStatisticsByArea = (
  referer_id: string,
  cat_code: string,
  fromDate: string,
  toDate: string,
  isSums: boolean
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      cat_code: cat_code,
      fromdate: fromDate,
      todate: toDate,
      is_sums: isSums.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/vendorsClicksStatisticsAreaByCat`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsClicksStatisticsRaw = (
  referer_id: string,
  cat_code: string,
  city_code: string,
  fromDate: string,
  toDate: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      cat_code: cat_code,
      city_code: city_code,
      fromdate: fromDate,
      todate: toDate,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/vendorsClicksStatisticsRaw`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorsEventsDetails = (
  referer_id: string,
  cat_code: string,
  area_code: string,
  is_sums: boolean,
  fromDate: string,
  toDate: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      ref_code: referer_id,
      cat_code: cat_code,
      area_code: area_code,
      is_sums: is_sums.toString(),
      from_date: fromDate,
      to_date: toDate,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/eventsDetails`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getExpensesData = (
  fromDate: string,
  toDate: string,
  is_sums: boolean
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
      is_sums: is_sums.toString(),
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/expensesSpec`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const deleteExpense = (sortDate: string, group_code: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      sortdate: sortDate,
      group_code: group_code,
    }).toString();
    fetchRequest(`${config.url.API_URL}/referer/deleteExpense`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const updateExpense = (
  id: string,
  sortDate: string,
  group_code: string,
  expenses: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      id: id,
      sortdate: sortDate,
      group_code: group_code,
      expenses: expenses,
    }).toString();
    fetchRequest(`${config.url.API_URL}/referer/updateExpense`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateExpense failed!!!");
      });
  });
};

export const insertExpense = (
  sortDate: any,
  group_code: string,
  expenses: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      sortdate: getDateReverseFormat(sortDate),
      group_code: group_code,
      expenses: expenses,
    }).toString();
    fetchRequest(`${config.url.API_URL}/referer/insertExpense`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("insertExpense failed!!!");
      });
  });
};

export const getGroupsByVendorId = () => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(`${config.url.API_URL}/data/groupsByVendor`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        var obj: { [key: string]: string } = {};
        res.forEach((element: any) => {
          if (!obj.hasOwnProperty(element.code)) {
            obj[element.code] = element.dsc;
          }
        });
        resolve(obj);
      })
      .catch(function (error) {
        resolve("getGroupsByVendorId failed!!!");
      });
  });
};

export const getAllVendors = () => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(`${config.url.API_URL}/user/getAllVendors`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res);
      })
      .catch(function (error) {
        resolve("getVendors failed!!!");
      });
  });
};

export const getVendorHamlatza = (fromDate: string, toDate: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/vendorHamlatza`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getVendorLeads = (fromDate: string, toDate: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/vendorLeads`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};
