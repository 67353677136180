import { HeaderParameters } from "../../data/Header-Context";
import CustomPage, {
  floatingButton,
} from "../../components/utils/CustomElements/CustomPage";
import { isMobile } from "react-device-detect";

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  // IonButton,
  IonCard,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  isPlatform,
  // isPlatform,
} from "@ionic/react";
import CustomToggle from "../../components/utils/CustomElements/CustomToggle";
import {
  getVendorSettings,
  updateVendorSetting,
} from "../../services/VendorDetailsService";
import { toast } from "react-toastify";
import VendorDetailsContext from "../../data/VendorDetails-context";
import CustomInput from "../../components/utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import { addOutline } from "ionicons/icons";
import CustomAreYouSureAlert from "../../components/utils/CustomElements/CustomAreYouSureAlert";

const Settings: React.FC = () => {
  const VendorDetailsCxt = useContext(VendorDetailsContext);
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const [vendorEvents, setVendorEvents] = useState<any>([]);
  const [definedRange, setDefinedRange] = useState<any>([]);
  const [threshold, setThreshold] = useState<number>();
  const [minEvents, setMinEvents] = useState<number>();
  const [isNotify, setIsNotify] = useState<boolean>(true);
  const thresholdRef = useRef<any>();
  const minEventsRef = useRef<any>();
  const [isShowDeleteButton, setIsShowDeleteButton] = useState<boolean>(false);
  const selectedAllRef = useRef(null);

  const { errors, control } = useForm({
    mode: "onChange",
  });

  const floatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      updateVendorSetting(
        "0",
        "notify_treshold",
        thresholdRef.current.value
      ).then((result) => {
        if (result === "OK") {
          updateVendorSetting(
            "0",
            "notify_min_events",
            minEventsRef.current.value
          ).then((result) => {
            if (result === "OK") {
              toast.info("השינויים נשמרו בהצלחה");
            }
          });
        }
      });
    },
    title: "שמור שינויים",
  };

  useEffect(() => {
    // האם להפעיל התראות
    getVendorSettings("is_notify").then((result: any) => {
      if (result && result.length > 0) {
        setIsNotify(result[0].value);
      }
    });

    // סף תנועה לא מכניסה
    getVendorSettings("notify_treshold").then((result: any) => {
      if (result && result.length > 0) {
        setThreshold(result[0].value);
      }
    });

    // מינימום איוונטים
    getVendorSettings("notify_min_events").then((result: any) => {
      if (result && result.length > 0) {
        setMinEvents(result[0].value);
      }
    });

    // איוונטים
    getVendorSettings("event").then((result) => {
      if (result) {
        setVendorEvents(result);
      } else {
        toast.info(
          "אין העדפה לסוגי איוונטים, המערכת תציג את כל סוגי האיוונטים"
        );
      }
    });

    // תאריך
    getVendorSettings("date").then((result: any) => {
      if (result) {
        setDefinedRange(result);
        var selectedPeriodID = result?.filter((obj: any) => {
          return obj.active === true;
        });
        if (selectedPeriodID && selectedPeriodID[0]) {
          setSelectedPeriod(selectedPeriodID[0].id);
        } else {
          // set to default (חודש נוכחי)
          setSelectedPeriod("5");
        }
      }
    });
  }, []);

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: true,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  return (
    <CustomPage
      headerParameters={headerProps}
      title={"הגדרות מערכת"}
      pageName={"somthing"}
      floatingButton={floatingButton}
      fixedPage={true}
    >
      <IonGrid fixed={true}>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeMd="6">
            <h3 className={`bold-text ${!isMobile ? " white-text" : ""}`}>
              תאריך ברירת מחדל
            </h3>
            <IonCard className="ion-padding ion-no-margin">
              <IonList class="ion-margin-bottom">
                <IonRadioGroup
                  value={selectedPeriod}
                  onIonChange={(e) => {
                    if (selectedPeriod) {
                      setSelectedPeriod(e.detail.value);
                      updateVendorSetting(e.detail.value, "date", "1").then(
                        (result) => {
                          if (result) {
                            VendorDetailsCxt.setDefaultPeriod(e.detail.value);
                          }
                        }
                      );
                    }
                  }}
                >
                  {definedRange &&
                    definedRange.map((obj: any) => {
                      return (
                        <IonItem lines="full" mode="md" key={obj.id}>
                          <IonLabel>{obj.desc}</IonLabel>
                          <IonRadio slot="start" value={obj.id} />
                        </IonItem>
                      );
                    })}
                </IonRadioGroup>
              </IonList>
            </IonCard>
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <h3 className={`bold-text ${!isMobile ? " white-text" : ""}`}>
              התראות:
            </h3>
            <IonCard className="ion-padding ion-no-margin">
              <CustomToggle
                titleTxt={"התראות פעילות"}
                checked={isNotify}
                onIonChange={(e: any) => {
                  updateVendorSetting(
                    "0",
                    "is_notify",
                    e.detail.checked === true ? "1" : "0"
                  ).then((result) => {
                    if (result) {
                    }
                  });
                }}
              />
              {minEvents !== undefined && threshold !== undefined && (
                <>
                  <CustomInput
                    name="eventsMin"
                    errors={errors}
                    control={control}
                    label="מינימום חשיפות לקטגוריה"
                    required={true}
                    type="number"
                    value={minEvents}
                    ref={minEventsRef}
                  />

                  <CustomInput
                    type="number"
                    ref={thresholdRef}
                    value={threshold}
                    name="threshold"
                    errors={errors}
                    control={control}
                    label="סף תנועה שלא הומרה (%)"
                    required={true}
                  />
                </>
              )}
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center">
          <IonCol>
            <h3 className="bold-text">אילו איוונטים מעניינים אותך?</h3>
            <IonCard className="ion-padding ion-no-margin">
              <CustomToggle
                ref={selectedAllRef}
                titleTxt={"בחר/י הכל"}
                onIonChange={(e: any) => {
                  setIsShowDeleteButton(true);
                  selectedAllRef.current = e.detail.checked;
                }}
              />

              <CustomAreYouSureAlert
                cancelFunc={() => {
                  setIsShowDeleteButton(false);
                }}
                continueFunc={() => {
                  setIsShowDeleteButton(false);
                  setVendorEvents((vendorEvents: any) => {
                    const updatedVendorEvents = [...vendorEvents];
                    updatedVendorEvents.map((VendorEvent: any) => {
                      return (VendorEvent.active = selectedAllRef.current);
                    });
                    return updatedVendorEvents;
                  });
                }}
                isOpen={isShowDeleteButton}
                message={"האם את/ה בטוח/ה?"}
                continueText={"שמירה"}
              ></CustomAreYouSureAlert>
              <IonList className="toggele-item-list right">
                {vendorEvents &&
                  vendorEvents.map((obj: any) => {
                    return (
                      <IonItem key={obj.id} className="toggle-token" mode="ios">
                        <IonCheckbox
                          className="ion-no-margin"
                          checked={obj.active}
                          onIonChange={(e: any) => {
                            setVendorEvents((vendorEvents: any) => {
                              const updatedVendorEvents = [...vendorEvents];
                              const updatedVendorEventsIndex =
                                updatedVendorEvents.findIndex(
                                  (item) => item.id === obj.id
                                );
                              updatedVendorEvents[
                                updatedVendorEventsIndex
                              ].active = e.detail.checked;
                              return updatedVendorEvents;
                            });

                            updateVendorSetting(
                              obj.id,
                              "event",
                              e.detail.checked === true ? "1" : "0"
                            ).then((result) => {
                              if (result) {
                              }
                            });
                          }}
                        />
                        <IonLabel className="ion-no-margin">
                          <p>{obj.desc}</p>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomPage>
  );
};

export default Settings;
