import React, { ComponentProps, FC, useRef } from "react";
import { IonCol, IonGrid, IonModal, IonRow, isPlatform } from "@ionic/react";
import CustomHeader from "../CustomHeader/Header";
import { HeaderParameters } from "../../../../data/Header-Context";
import CustomPageTitle from "../CustomPageTitle/CustomPageTitle";
import CustomContent from "../CustomContent";
import "./CustomModal.scss";
import { isMobile } from "react-device-detect";

type IonModalProps = ComponentProps<typeof IonModal>;
interface ModalProps extends IonModalProps {
  headerParameters: HeaderParameters;
  title?: string;
  subTitle?: string;
  closeModal: () => void;
  pageName: string; //send the  parent function name
}

const CustomModal: FC<ModalProps> = ({
  headerParameters,
  title,
  subTitle,
  pageName,
  closeModal,
  ...props
}) => {
  const ionModal = useRef<HTMLIonModalElement>(null);
  const modalCss = () => {
    let wraperClassName = "modal-wraper ";
    switch (pageName) {
      case "HandleOpenHours":
        wraperClassName += "modal-wraper-wide";
        break;
      case "GalleryHandler":
        wraperClassName += "modal-wraper-wide";
        break;
      case "CustomImageEditor":
        wraperClassName += "modal-wraper-wide";
        break;
      case "AddEditProduct":
        wraperClassName += "modal-wraper-wide";
        break;
      case "HandleMemberCoupon":
        wraperClassName += "modal-wraper-wide";
        break;
      case "ProfileSettings":
        wraperClassName += "modal-wraper-wide";
        break;
      case "MemberCampaigns":
        wraperClassName += "modal-wraper-wide";
        break;
      case "GalleryItemPreview":
        wraperClassName += "modal-wraper-fullscreen";
        break;
      case "DateRangePicker":
        wraperClassName += "modal-wraper-wide";
        break;
      case "TrafficSourcePage":
        wraperClassName += "modal-wraper-fullscreen";
        break;
      case "CampaignGraph":
      case "createCampaign":
        wraperClassName += "modal-wraper-wide";
        break;
      case "errorCampaign":
        wraperClassName += "modal-wraper-wide small-high";
        break;
      default:
        wraperClassName += "modal-narrow";
    }
    return wraperClassName;
  };

  const _headerParameters = { ...headerParameters };
  if (headerParameters.isCloseModalBtn === undefined) {
    _headerParameters.isCloseModalBtn = true;
    _headerParameters.closeModalFunc = () => {
      ionModal.current?.dismiss();
    };
  }

  return (
    <IonModal
      ref={ionModal}
      cssClass={!isMobile ? modalCss() : ""}
      backdropDismiss={false}
      onDidDismiss={() => {
        closeModal();
      }}
      {...props}
    >
      <CustomHeader headerParameters={_headerParameters}></CustomHeader>
      <CustomContent
        isRefreshPage={false}
        className={`modal-page  ${
          pageName === "GalleryItemPreview" ? "modal-item-preview" : ""
        }`}
      >
        <IonGrid>
          <IonRow className="ion-justify-content-between">
            {title && (
              <IonCol size={"11"} className="ion-no-padding">
                <CustomPageTitle
                  title={title}
                  subTitle={subTitle}
                  subTitleClass="modal-subtitle"
                  isBreadCrumbs={_headerParameters.isBreadCrumbs}
                />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>

        {props.children}
      </CustomContent>
    </IonModal>
  );
};

export default CustomModal;
