import React, { useCallback, useState } from "react";
import { Capacitor, Plugins } from "@capacitor/core";
import { Redirect } from "react-router-dom";
import UserContext, { userData } from "./Auth-context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import { toast } from "react-toastify";
const parseString = require("xml2js").parseString;
const { Storage } = Plugins;

export const AuthContextProvider: React.FC = (props) => {
  // the reactive values
  const [authValues, setAuthValues] = React.useState<userData>();
  const [loading, setLoading] = useState<boolean>(false);

  const initUserContext = useCallback(async () => {
    const userData = await Storage.get({ key: "userData" });
    const storedUserData = userData.value
      ? JSON.parse(userData.value)
      : undefined;
    if (storedUserData) {
      setAuthValues(() => ({
        Token: storedUserData.Token,
        Email: storedUserData.Email,
        Id: storedUserData.Id,
        Name: storedUserData.Name,
        IsAdmin: storedUserData.IsAdmin,
        OrgId: storedUserData.OrgId,
        FirebaseToken: storedUserData.FirebaseToken,
      }));
      return <Redirect to="/Home" />;
    }
  }, []);

  const login = (email: string, password: string, firebaseToken: any) => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        email: email,
        password: password,
        FbsId: firebaseToken,
        Platform: Capacitor.getPlatform(),
      }).toString();
      fetchRequest(`${config.url.API_URL}/user/login`, "post", data)
        .then((response: any) => {
          if (response) {
            var res = JSON.parse(response);
            if (res.message == "") {
              const userData: userData = {
                Id: res.UserId,
                Token: res.Token,
                Email: email,
                Name: res.Name,
                IsAdmin: res.IsAdmin,
                OrgId: res.OrgId,
                FirebaseToken: firebaseToken,
              };
              Storage.set({
                key: "userData",
                value: JSON.stringify(userData),
              });
              setAuthValues(userData);
            } else {
              resolve(res.message);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.status) {
            resolve("מייל או סיסמה שגויים");
          } else {
            resolve(error);
          }
        });
    });
  };

  const changeVendorByAdmin = (
    vendorId: number,
    adminEmail: string,
    adminName: string,
    isAdmin: boolean | null,
    orgId: string
  ) => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        vendor_id: vendorId.toString(),
        FbsId: authValues?.FirebaseToken!,
        Platform: Capacitor.getPlatform(),
        Email: authValues?.Email!,
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/user/changeVendorByAdmin`,
        "post",
        data
      )
        .then((response: any) => {
          if (response) {
            var res = JSON.parse(response);
            const userData: userData = {
              Id: vendorId,
              Token: res.Token,
              Email: adminEmail,
              Name: adminName,
              IsAdmin: isAdmin,
              OrgId: orgId,
              FirebaseToken: authValues?.FirebaseToken!,
            };
            Storage.set({
              key: "userData",
              value: JSON.stringify(userData),
            });
            setAuthValues(userData);
            resolve(true);
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.status) {
            resolve("שגיאה בשינוי ספק");
          } else {
            resolve(error);
          }
        });
    });
  };

  const loginTest = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        query_type: "sp",
        db: "ppidb",
        from_date: "2010-01-01 00:00:00",
        to_date: "2021-12-31 23:59:59",
        vendor_id: "5",
      }).toString();
      fetchRequest(`${config.url.API_URL}/vendorApi/vendorsMain`, "post", data)
        .then((response: any) => {
          var res = JSON.parse(response);
          console.log(res);
          const userData = {
            Email: "yaroni@b-soft.co.il",
            Id: 53408,
            Token:
              "W8Q5QxdQnH54Z6s4MnO3vPuWtqlExV5OgEgG8qM719jDkr3gTyspOE4kbc9RWEak8e0Q1nLKzVQGTWo5n@MZtGokMKR5m70uNLpbjtJ4MS4rWBFLtSrbNg3Ob1JZHh2NvbFayen0R3Fx9mGXDdyIT0j2d2EvZE7OLwAMtWTioGlypB4LEiITmzdx5KNPjWv7lrYUYKwVRV$BbSNPCzgohYQ0cCmaEYah3TVUs7HD$q4XunDcwqTWpghg1nnif8lt3dqebq5Vou0tod2@SAENzz2plIzZUyHiuYYVoiGyHhEfw@fV7kDgqm6T0@KR0xmlHASo8Pl9DisdfynW82RlL1O1CrkKztntY@ELM3xjPi7o1JFGUsiTkUeO$QvMaO0fFpser@Ekb1n7rlOBrbgL8FFkNuCMVqI$Xjmb9QR6JjgirFHY6EHUpbiK3xMw$yVJlNMkey51MtcmtiNyPPgAMf8tS8CochJXIpzgds7VJQxf3wQehnXGRz8YvfEr1ZDY",
            Name: "NameTest",
            IsAdmin: false,
            OrgId: null,
            FirebaseToken: "",
          };

          Storage.set({
            key: "userData",
            value: JSON.stringify(userData),
          });

          setAuthValues(userData);
        })
        .catch(function (error) {
          setLoading(false);
          resolve(error);
        });
    });
  };

  const logout = useCallback(async (calbackFunc: () => void) => {
    new Promise((resolve) => {
      try {
        Storage.clear();
        setAuthValues({
          Token: null,
          Email: null,
          Id: null,
          Name: null,
          IsAdmin: null,
          OrgId: null,
          FirebaseToken: null,
        });
        resolve("Success");
      } catch (e) {
        resolve("failed");
      }
    }).then((value) => {
      if (value === "Success") {
        calbackFunc();
        //window.location.reload(false);
        window.location.reload();
      } else {
        toast.error("ארעה שגיאה");
      }
    });
  }, []);

  // the store object
  let state = {
    authValues,
    login,
    loginTest,
    logout,
    initUserContext,
    changeVendorByAdmin,
  };

  // wrap the application in the provider with the initialized context
  return (
    <UserContext.Provider value={state}>
      {props.children}{" "}
      <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader>
    </UserContext.Provider>
  );
};

export default UserContext;
