import React, { useContext, useEffect, useState } from "react";
import {
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  isPlatform,
  IonHeader,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import { logOutOutline, closeOutline, cart, desktop } from "ionicons/icons";
import { addIcons } from "ionicons";
import { useLocation } from "react-router";

import {
  home,
  person,
  settings,
  storefrontOutline,
  openOutline,
} from "ionicons/icons";
import UserContext from "../../data/AuthContextProvider";
import "./hamburger.scss";
import { config } from "../../Constants";
import { isMobile } from "react-device-detect";

addIcons({
  cart: cart,
  desktop: desktop,
});
export interface externalLinks {
  icon: string;
  title: string;
  pageLink: string;
  id: number;
}

const Hamburger: React.FC<{ sideMenuIndexPos: (isOpen: boolean) => void }> = (
  props
) => {
  const { logout, authValues } = useContext(UserContext);
  const [hideMenuButton, setHideMenuButton] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (authValues?.Id === config.adminId) {
      setHideMenuButton(true);
    } else {
      setHideMenuButton(false);
    }
  }, [authValues]);
  return (
    <>
      <IonMenu
        side={!isMobile ? "start" : "end"}
        contentId="hamburgerMain"
        onIonWillOpen={() => props.sideMenuIndexPos(true)}
        onIonDidClose={() => props.sideMenuIndexPos(false)}
      >
        {isMobile && (
          <IonHeader className="ion-no-border">
            <IonToolbar
              mode="ios"
              className={isMobile ? "hamburger-toolbar" : ""}
            >
              <IonMenuToggle autoHide={false}>
                <IonItem
                  lines="none"
                  className="item-trns"
                  detail={false}
                  button
                  routerDirection="none"
                >
                  <div className="ion-margin-horizontal">
                    <p className="small-title bold-text underline">
                      {authValues?.Name}
                    </p>
                  </div>
                </IonItem>
              </IonMenuToggle>
              <IonButtons slot="end">
                <IonMenuToggle autoHide={true}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonMenuToggle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        {
          <IonContent>
            <IonList>
              {/* {!isMobile && (
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    lines="none"
                    className="side-menu-desktop-header"
                    detail={false}
                    button
                    routerDirection="none"
                  >
                    <div className="ion-margin-horizontal">
                      <p className="small-title bold-text underline">
                        {authValues?.Name}
                      </p>
                    </div>
                  </IonItem>
                </IonMenuToggle>
              )} */}
              <IonMenuToggle autoHide={false}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/Home"
                  routerDirection="none"
                  lines="none"
                  className={location.pathname === "/Home" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={home}></IonIcon>
                  <IonLabel>עמוד הבית</IonLabel>
                </IonItem>
              </IonMenuToggle>
              {authValues?.IsAdmin && (
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    detail={false}
                    button
                    routerLink="/Permissions"
                    routerDirection="none"
                    lines="none"
                    className={
                      location.pathname === "/Permissions" ? "active" : ""
                    }
                  >
                    <IonIcon slot="start" icon={person}></IonIcon>
                    <IonLabel>הרשאות משתמשים</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              )}
              <IonMenuToggle autoHide={false}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/Products"
                  routerDirection="none"
                  lines="none"
                  className={location.pathname === "/Products" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={storefrontOutline}></IonIcon>
                  <IonLabel>מוצרים</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/Campaigns"
                  routerDirection="none"
                  lines="none"
                  className={location.pathname === "/Campaigns" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={openOutline}></IonIcon>
                  <IonLabel>קמפיינים</IonLabel>
                </IonItem>
              </IonMenuToggle>
              {/* <IonMenuToggle autoHide={hideMenuButton}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/TrafficSource"
                  routerDirection="none"
                  lines="none"
                  className={
                    location.pathname === "/TrafficSource" ? "active" : ""
                  }
                >
                  <IonIcon slot="start" icon={tableChart}></IonIcon>
                  <IonLabel>פירוט הנתונים</IonLabel>
                </IonItem>
              </IonMenuToggle> */}
              {/* <IonMenuToggle autoHide={hideMenuButton}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/SpecifyExpenses"
                  routerDirection="none"
                  lines="none"
                  className={
                    location.pathname === "/SpecifyExpenses" ? "active" : ""
                  }
                >
                  <IonIcon slot="start" icon={tableChart}></IonIcon>
                  <IonLabel>רישום הוצאות ROI</IonLabel>
                </IonItem>
              </IonMenuToggle> */}
              {/* <IonMenuToggle autoHide={hideMenuButton}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/Hamlatza"
                  routerDirection="none"
                  lines="none"
                  className={location.pathname === "/Hamlatza" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={tableChart}></IonIcon>
                  <IonLabel>המלצה לפרסום</IonLabel>
                </IonItem>
              </IonMenuToggle> */}
              {/* <IonMenuToggle autoHide={hideMenuButton}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/VendorLeads"
                  routerDirection="none"
                  lines="none"
                  className={
                    location.pathname === "/VendorLeads" ? "active" : ""
                  }
                >
                  <IonIcon slot="start" icon={tableChart}></IonIcon>
                  <IonLabel>לידים</IonLabel>
                </IonItem>
              </IonMenuToggle> */}
              <span className="menu-divider" />
              <IonMenuToggle autoHide={hideMenuButton}>
                <IonItem
                  detail={false}
                  button
                  routerLink="/Settings"
                  routerDirection="none"
                  lines="none"
                  className={location.pathname === "/Settings" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={settings}></IonIcon>
                  <IonLabel>הגדרות</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem
                  detail={false}
                  button
                  onClick={() => {
                    logout(() => {
                      props.sideMenuIndexPos(false);
                    });
                  }}
                  lines="none"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  <IonIcon slot="start" icon={logOutOutline}></IonIcon>
                  <IonLabel>התנתק</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        }
      </IonMenu>
    </>
  );
};

export default Hamburger;
