import React, { useContext, useEffect } from "react";
import { IonRouterOutlet, isPlatform } from "@ionic/react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { isMobile } from "react-device-detect";

import Home from "../../pages/HomePage/Home";

import { Plugins } from "@capacitor/core";
import Transition from "../utils/Transition";
import Permissions from "../Permissions";
import Products from "../Products";
import Campaigns from "../Campaigns";

import Settings from "../../pages/Settings/Settings";
import TrafficSource from "../TrafficSource";
import ExpensesMaint from "../ExpensesMaint";
import VendorHamlatza from "../../components/VendorHamlatza";
import VendorLeads from "../../components/VendorLeads";
import { config } from "../../Constants";
import UserContext from "../../data/AuthContextProvider";

const Routing: React.FC = () => {
  const location = useLocation();
  const { authValues } = useContext(UserContext);

  useEffect(() => {
    if (isMobile && !isPlatform("mobileweb")) {
      if (location.pathname.toLowerCase() === "/home") {
        Plugins.App.addListener("backButton", Plugins.App.exitApp);
      } else {
        Plugins.App.removeAllListeners();
      }
    }
  });

  return (
    <IonRouterOutlet id="hamburgerMain">
      <Redirect path="/" to="/Home" exact></Redirect>
      <AnimatePresence exitBeforeEnter>
        {/* {authValues?.Id === config.adminId ? (
          <Transition>
            <Home />
          </Transition>
        ) : ( */}
        <Switch key={location.pathname}>
          <Route
            path="/login"
            render={() => <Redirect to="/Home" />}
            exact={true}
          />
          <Route path="/Home" exact>
            <Transition>
              <Home />
            </Transition>
          </Route>
          <Route path="/Permissions" exact>
            <Transition>
              <Permissions />
            </Transition>
          </Route>
          <Route path="/Products" exact>
            <Transition>
              <Products />
            </Transition>
          </Route>
          <Route path="/Campaigns" exact>
            <Transition>
              <Campaigns />
            </Transition>
          </Route>
          <Route path="/Campaigns/:id" exact>
            <Transition>
              <Campaigns />
            </Transition>
          </Route>

          <Route path="/TrafficSource" exact>
            <Transition>
              <TrafficSource />
            </Transition>
          </Route>
          <Route path="/SpecifyExpenses" exact>
            <Transition>
              <ExpensesMaint />
            </Transition>
          </Route>
          <Route path="/Hamlatza" exact>
            <Transition>
              <VendorHamlatza />
            </Transition>
          </Route>
          <Route path="/VendorLeads" exact>
            <Transition>
              <VendorLeads />
            </Transition>
          </Route>
          <Route path="/Settings" exact>
            <Transition>
              <Settings />
            </Transition>
          </Route>
        </Switch>
        {/* )} */}
      </AnimatePresence>
    </IonRouterOutlet>
  );
};

export default Routing;
