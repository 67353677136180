import React, { useContext, useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsClicksStatistics } from "../services/VendorDetailsService";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import ActivityByArea from "./ActivityByArea";
import ActivityByCatHour from "./ActivityByCatHour";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import EventsDetails from "./EventsDetails";
import VendorDetailsContext from "../data/VendorDetails-context";
import { isPlatform } from "@ionic/core";
import { isMobile } from "react-device-detect";

const ActivityByCatXArea: React.FC<{
  show: boolean;
  closeModal: (isDeleted: boolean) => void;
  referer_id: string;
  referer_desc: string;
  startDate: Date;
  endDate: Date;
}> = (props) => {
  const [ActivityCatArea, setActivityCatArea] = useState<any>([]);
  const title = !isMobile
    ? props.referer_desc + " > נתונים לפי קטגוריה"
    : "נתונים לפי קטגוריה";
  const [selected_cat_code, setSelectedCatCode] = useState("-1");
  const [selected_cat_title, setSelectedCatTitle] = useState("");
  const [showActivityByAreaGrid, setShowActivityByAreaGrid] = useState(false);
  const [ShowActivityByCatHour, setShowActivityByCatHour] = useState(false);
  const [sums, setSums] = useState<any>([]);
  const VendorDetailsCxt = useContext(VendorDetailsContext);
  const [showEventsDetailsGrid, setShowEventsDetailsGrid] = useState(false);
  const [selected_desc, setSelectedDesc] = useState("");

  const headerParameters: HeaderParameters = {
    modalType: "modal",
    isBreadCrumbs: true,
  };

  // const limitedDates: number = 5;

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.startDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(props.endDate);

  useEffect(() => {
    getVendorsClicksStatistics(
      props.referer_id,
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate),
      false
    ).then((result) => {
      getVendorsClicksStatistics(
        props.referer_id,
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        true
      ).then((result) => {
        setSums({ data: result });
      });
      setActivityCatArea(result);
    });
  }, [selectedEndDate, selectedStartDate]);

  const activityByAreaGridFunc = (selectedRow: any) => {
    setSelectedCatCode(selectedRow.cat_code.toString());
    setSelectedCatTitle(selectedRow.cat_desc);
    setShowActivityByAreaGrid(true);
  };

  const activityByHourFunc = (selectedRow: any) => {
    setSelectedCatCode(selectedRow.cat_code.toString());
    setSelectedCatTitle(selectedRow.cat_desc);
    setShowActivityByCatHour(true);
  };

  const gridActivities = [
    {
      icon: "category",
      btnId: "hour",
      tooltip: "לפי קטגוריה ואזור",
      onClick: activityByAreaGridFunc,
    },
    {
      icon: "query_builder",
      btnId: "hour",
      tooltip: "פריסה שעתית",
      onClick: activityByHourFunc,
    },
  ];

  const columns = [
    {
      field: "cat_code",
      title: "קוד קטגוריה",
      width: "8%",
      type: "numeric",
    },
    {
      field: "cat_desc",
      title: "קטגוריה",
      width: "15%",
    },
    {
      title: "איבנטים",
      field: "tot_events_count",
      type: "numeric",
      width: "10%",
      render: (row: any) => {
        const eventsDetailsFunc = () => {
          setSelectedDesc(row.cat_desc);
          setSelectedCatCode(row.cat_code);
          setShowEventsDetailsGrid(true);
        };
        return row.tot_events_count > 0 ? (
          <button className="link-button" onClick={eventsDetailsFunc}>
            {row.tot_events_count}
          </button>
        ) : (
          row.tot_events_count
        );
      },
    },
    {
      field: "tot_clicks_count",
      title: "חשיפות",
      width: "10%",
      type: "numeric",
    },
    {
      field: "clicks_charged",
      title: "חשיפות לחיוב",
      width: "10%",
      type: "numeric",
    },
    {
      field: "amount_charged",
      title: "הכנסות",
      width: "10%",
      type: "numeric",
    },
    {
      field: "clicks_above_budget",
      title: "מעל תקציב",
      width: "10%",
      type: "numeric",
    },
    {
      field: "clicks_frauds",
      title: "הונאה",
      width: "10%",
      type: "numeric",
    },
    {
      field: "clicks_black_list",
      title: "רשימה שחורה",
      width: "10%",
      type: "numeric",
    },
  ];

  return (
    <React.Fragment>
      <CustomModal
        pageName={"TrafficSourcePage"}
        headerParameters={headerParameters}
        title={isMobile ? props.referer_desc + " > " + title : ""}
        closeModal={() => {
          props.closeModal(false);
        }}
        isOpen={props.show}
      >
        <CustomMaterialTable
          title={title}
          rows={ActivityCatArea}
          columns={columns}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setSelectedStartDate && setSelectedStartDate(startDate);
            setSelectedEndDate && setSelectedEndDate(endDate);
          }}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          summaryTbl={sums}
          limitedDates={undefined}
          gridActivities={gridActivities}
          loaderDuration={30000}
          isDateRange={true}
        />
      </CustomModal>

      {showActivityByAreaGrid && (
        <ActivityByArea
          referer_id={props.referer_id}
          cat_code={selected_cat_code}
          cat_title={props.referer_desc + " > " + selected_cat_title}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          show={showActivityByAreaGrid}
          closeModal={(isDeleted: boolean) => {
            setShowActivityByAreaGrid(false);
          }}
        ></ActivityByArea>
      )}

      {ShowActivityByCatHour && (
        <ActivityByCatHour
          referer_id={props.referer_id}
          cat_code={selected_cat_code}
          cat_title={props.referer_desc + " > " + selected_cat_title}
          forDate={selectedStartDate}
          show={ShowActivityByCatHour}
          closeModal={(isDeleted: boolean) => {
            setShowActivityByCatHour(false);
          }}
        ></ActivityByCatHour>
      )}

      {showEventsDetailsGrid && (
        <EventsDetails
          referer_id={props.referer_id}
          cat_code={selected_cat_code}
          area_code=""
          referer_desc={props.referer_desc}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          show={showEventsDetailsGrid}
          closeModal={(isDeleted: boolean) => {
            setShowEventsDetailsGrid(false);
          }}
        ></EventsDetails>
      )}
    </React.Fragment>
  );
};

export default ActivityByCatXArea;
