import React from "react";

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonImg,
  IonBackButton,
  IonIcon,
  IonLabel,
  isPlatform,
  IonRouterLink,
} from "@ionic/react";
// import Logo2 from "../../../../images/logos/logob-144-mobile.svg";
// import LogoNegative from "../../../../images/logos/logob-144-mobile-negative.svg";
import { close, pencil, chevronForwardOutline } from "ionicons/icons";
import {
  iconEditWhite,
  closeBlack,
  menu,
  downloadBlack,
  download,
  trash,
  trashDark,
} from "../../../../images/index";
import Logo from "../../../../images/logos/logo.svg";

import { isMobile } from "react-device-detect";
import CustomDeleteButton from "../CustomDeleteButton";
import CustomCloseButton from "../CustomCloseButton";
import "./header.scss";
import CustomPdfButton from "../CustomPdfButton";
import CustomeMoreOptions from "../CustomeMoreOptions";
import { HeaderParameters } from "../../../../data/Header-Context";
import { useContext } from "react";
import UserContext from "../../../../data/Auth-context";
const CustomHeader: React.FC<{
  headerParameters: HeaderParameters;
}> = (props) => {
  const { authValues } = useContext(UserContext);
  const isShowMultiChoiceNumber =
    props.headerParameters.numOfMultiSelected !== undefined ? true : false;

  const modalHandler =
    props.headerParameters.modalType === "itemPreview"
      ? "modal-item-preview"
      : "modal";

  const getTitle = () => {
    const Name = authValues?.Name;
    const newDate = new Date();
    const currentHour = newDate.getHours();
    let partOfTeDay = "";
    if (6 <= currentHour && currentHour < 11) {
      partOfTeDay = "בוקר טוב ";
    }
    if (11 <= currentHour && currentHour < 16) {
      partOfTeDay = "צהריים טובים  ";
    }
    if (16 <= currentHour && currentHour < 19) {
      partOfTeDay = 'אחה"צ טובים ';
    }
    if (19 <= currentHour && currentHour < 21) {
      partOfTeDay = "ערב טוב ";
    }
    if (21 <= currentHour || currentHour < 6) {
      partOfTeDay = "לילה טוב ";
    }
    const job = authValues?.IsAdmin ? "מנהל" : "משתמש";
    return partOfTeDay + " " + Name + ". הרשאתך במערכת: " + job;
  };

  return (
    <IonHeader
      mode={props.headerParameters.modalType ? "ios" : undefined}
      className={
        "ion-no-border " +
        (props.headerParameters.modalType === "modal"
          ? "ion-padding-top ion-padding-horizontal"
          : "")
      }
    >
      <IonToolbar
        className={`${props.headerParameters.modalType ? modalHandler : ""} 
                   ${props.headerParameters.isLogin ? "modal" : ""}`}
        mode={isMobile ? "ios" : undefined}
      >
        <IonButtons
          slot={
            props.headerParameters.modalType === "modal" && !isMobile
              ? "end"
              : "start"
          }
        >
          {props.headerParameters.isShowBackButton === true &&
            isShowMultiChoiceNumber === false &&
            isMobile && (
              <IonBackButton
                text=""
                icon={chevronForwardOutline}
                defaultHref={`/Home`}
              ></IonBackButton>
            )}
          {props.headerParameters.isCloseModalBtn === true &&
            isShowMultiChoiceNumber === false && (
              <CustomCloseButton
                icon={
                  props.headerParameters.modalType === "modal" && !isMobile
                    ? closeBlack
                    : chevronForwardOutline
                }
                isPageHaveChanges={props.headerParameters.isPageHaveChanges}
                onClose={() => {
                  if (props.headerParameters.closeModalFunc) {
                    props.headerParameters.closeModalFunc();
                  }
                }}
              ></CustomCloseButton>
            )}
          {props.headerParameters.isShowHamburger === true &&
            isShowMultiChoiceNumber === false && (
              <IonMenuButton>
                <IonIcon icon={menu}></IonIcon>
              </IonMenuButton>
            )}

          {props.headerParameters.isShowGreeting === true && (
            <h4 className="large-title">{getTitle()}</h4>
          )}

          {isShowMultiChoiceNumber && (
            <>
              <IonButton
                onClick={() => {
                  if (
                    props.headerParameters.onCancelMultiSelected !== undefined
                  ) {
                    props.headerParameters.onCancelMultiSelected();
                  }
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
              {props.headerParameters.numOfMultiSelected! > 0 && (
                <IonLabel>{props.headerParameters.numOfMultiSelected}</IonLabel>
              )}
            </>
          )}
        </IonButtons>
        <IonButtons slot="end">
          {props.headerParameters.isShowLogo === true &&
            isShowMultiChoiceNumber === false && (
              <IonButton
                routerLink={`/Home`}
                className={isMobile ? "logo" : ""}
              >
                {/* <IonImg
                  className="headding-logo"
                  src={props.headerParameters.modalType ? LogoNegative : Logo}
                /> */}
                {/* <p
                  style={{
                    color: "black",
                    fontWeight: 900,
                    fontSize: "25px",
                  }}
                >
                  Black.Dog
                </p> */}
                <IonImg src={Logo} className="headding-logo" />
                {/* <IonIcon icon={logo} /> */}
              </IonButton>
            )}
          {props.headerParameters.isShowBusinessCardPreview !== undefined &&
            props.headerParameters.isShowBusinessCardPreview === true && (
              <IonRouterLink
                target="_blank"
                // href={GlobalCtx.externalLinkWithToken + "370"}
                className="underline white-text md-title"
              >
                תצוגה מקדימה
              </IonRouterLink>
            )}
          {props.headerParameters.isShowEditButton === true && (
            <IonButton
              onClick={() => {
                if (props.headerParameters.editBtnFunc) {
                  props.headerParameters.editBtnFunc();
                }
              }}
            >
              <IonIcon
                icon={
                  props.headerParameters.modalType === "modal"
                    ? iconEditWhite
                    : pencil
                }
              ></IonIcon>
            </IonButton>
          )}
          {props.headerParameters.isShowDeleteButton === true && (
            <CustomDeleteButton
              icon={
                props.headerParameters.modalType === "modal" ? trashDark : trash
              }
              onDelete={() => {
                if (props.headerParameters.deleteFunc) {
                  props.headerParameters.deleteFunc();
                }
              }}
            ></CustomDeleteButton>
          )}
          {props.headerParameters.pdfDownloadPdf !== undefined && isMobile && (
            <CustomPdfButton
              pdf={props.headerParameters.pdfDownloadPdf}
              icon={
                props.headerParameters.modalType === "modal"
                  ? downloadBlack
                  : download
              }
            ></CustomPdfButton>
          )}
          {props.headerParameters.moreOptionsButtons &&
            isMobile &&
            props.headerParameters.moreOptionsButtons.length > 0 && (
              <CustomeMoreOptions
                options={props.headerParameters.moreOptionsButtons}
              ></CustomeMoreOptions>
            )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
export default CustomHeader;
