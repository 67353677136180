import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import UserContext from "./data/AuthContextProvider";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/theme.scss";
import { useContext, useEffect, useState } from "react";
import { HeaderParameters } from "./data/Header-Context";
import React from "react";
import CustomHeader from "./components/utils/CustomElements/CustomHeader/Header";
import Login from "./pages/Login/Login";
import HeaderContextProvider from "./data/HeaderContextProvider";
import Routing from "./components/Navigation/Routing";
import Hamburger from "./components/Navigation/Hamburger";
import { Redirect, Route } from "react-router-dom";
import { isMobile } from "react-device-detect"; // npm install react-device-detect --save

const App: React.FC = () => {
  const { authValues, initUserContext } = useContext(UserContext);
  const [isMenuOpen, menuOpenHandler] = useState(false);
  const ballot = require("./images/icons/ballot.svg");

  useEffect(() => {
    initUserContext();
    // isWindows();
  }, [initUserContext]);

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isLogin: !authValues?.Token,
  };

  // const isWindows = () => {
  //   if (!window || !navigator) {
  //     return false;
  //   }

  //   const a: string =
  //     navigator.userAgent || navigator.vendor || (window as any).opera;
  //   console.log(/windows/i.test(a));
  //   console.log(a);
  //   return /windows/i.test(a);
  // };

  return (
    <IonApp dir="rtl" lang="he">
      <img src={ballot} />
      {!authValues?.Token ? (
        <React.Fragment>
          <CustomHeader headerParameters={headerParameters}></CustomHeader>
          <IonReactRouter>
            <React.Suspense fallback={null}>
              <IonRouterOutlet>
                <Redirect path="" to="/login" exact></Redirect>
                <Route path="/login">
                  <Login />
                </Route>
              </IonRouterOutlet>
            </React.Suspense>
          </IonReactRouter>
        </React.Fragment>
      ) : (
        <IonReactRouter>
          <HeaderContextProvider>
            <IonSplitPane
              contentId="hamburgerMain"
              className={isMobile && isMenuOpen ? "side-menu-mobile" : ""}
            >
              <React.Suspense fallback={null}>
                <Hamburger sideMenuIndexPos={menuOpenHandler}></Hamburger>
                <IonRouterOutlet id="hamburgerMain">
                  <Routing />
                </IonRouterOutlet>
              </React.Suspense>
            </IonSplitPane>
          </HeaderContextProvider>
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
