import React from "react";
import { IonAlert } from "@ionic/react";

export interface ButtonProperties {
  text: string;
  role: "cancel" | "save" | "ok" | "yes" | "no" | "other";
  cssClass?: string | string[];
  handler?: (
    value: any
  ) =>
    | boolean
    | void
    | {
        [key: string]: any;
      };
}

const CustomAlert: React.FC<{
  isOpen: boolean;
  header?: string;
  message?: string;
  buttons?: ButtonProperties[];
  subHeader?: string;
  onDismiss?: () => void;
}> = (props) => {
  if (props.buttons) {
    props.buttons.forEach((button) => {
      // if the user didnt add his own class
      if (!button.cssClass && button.role) {
        switch (button.role) {
          case "cancel":
            button.cssClass = "";
            break;
          case "save":
            button.cssClass = "";
            break;
          case "ok":
            button.cssClass = "";
            break;
          case "yes":
            button.cssClass = "";
            break;
          case "no":
            button.cssClass = "";
            break;
          case "other":
            button.cssClass = "";
            break;
        }
      }
    });
  }
  return (
    <IonAlert
      onDidDismiss={() => {
        if (props.onDismiss) {
          props.onDismiss();
        }
      }}
      isOpen={props.isOpen}
      header={props.header}
      subHeader={props.subHeader}
      message={props.message}
      buttons={props.buttons}
    ></IonAlert>
  );
};
export default CustomAlert;
