import React, { FC, ComponentProps } from "react";
import { useKeyboardState } from "@ionic/react-hooks/keyboard";
import { IonButton } from "@ionic/react";

type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  onCancel?: () => void;
  title?: string;
  fixed?: boolean;
}

const CustomPublishButton: FC<ButtonProps> = ({
  onCancel,
  title,
  ...props
}) => {
  const { isOpen, keyboardHeight } = useKeyboardState();
  return (
    <React.Fragment>
      <IonButton
        expand="block"
        type="submit"
        className={
          "main-button " + (props.fixed && !isOpen ? "fixed" : "ion-margin-top")
        }
        {...props}
      >
        {title ? title : "פרסם"}
      </IonButton>
      {onCancel && (
        <IonButton
          onClick={() => {
            onCancel();
          }}
        >
          {"ביטול"}
        </IonButton>
      )}
    </React.Fragment>
  );
};

export default CustomPublishButton;
