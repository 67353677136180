import { IonGrid } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import {
  getExpensesData,
  deleteExpense,
  updateExpense,
  insertExpense,
  getGroupsByVendorId,
} from "../services/VendorDetailsService";
import { toast } from "react-toastify";
import VendorDetailsContext from "../data/VendorDetails-context";
import { defaultStaticRanges } from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { HeaderParameters } from "../data/Header-Context";
import CustomPage from "./utils/CustomElements/CustomPage";

const ExpensesMaint: React.FC = () => {
  const VendorDetailsCxt = useContext(VendorDetailsContext);

  const [ExpensesMaintData, setExpensesMaintData] = useState<any>([]);
  const [groups, setGroups] = useState<any>({});
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();

  const [sums, setSums] = useState<{ data: any; summaryType?: string }>();

  const refreshGrid = () => {
    getExpensesData(
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate),
      false
    ).then((result) => {
      getExpensesData(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        true
      ).then((result) => {
        setSums({ data: result, summaryType: "calcTotal" });
      });
      setExpensesMaintData(result);
    });
  };

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  useEffect(() => {
    if (VendorDetailsCxt.GeneralInfo == null) {
      VendorDetailsCxt.initVendorDetailsContext();
    }

    getGroupsByVendorId().then((result: any) => {
      setGroups(result);
    });
  }, []);

  useEffect(() => {
    if (
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod &&
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod !== "-1"
    ) {
      setSelectedStartDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .startDate
      );
      setSelectedEndDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .endDate
      );
    } else if (
      VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
      VendorDetailsCxt.GeneralInfo?.SelectedToDate
    ) {
      setSelectedStartDate(VendorDetailsCxt.GeneralInfo?.SelectedFromDate);
      setSelectedEndDate(VendorDetailsCxt.GeneralInfo?.SelectedToDate);
    }
  }, [VendorDetailsCxt.GeneralInfo?.DefaultPeriod]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      refreshGrid();
    }
  }, [selectedEndDate, selectedStartDate]);

  const deleteRow = (selectedRow: any) => {
    deleteExpense(selectedRow.sort_date, selectedRow.group_id)
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה הוסרה בהצלחה");
          refreshGrid();
        } else {
          toast.warning("לא נמצאו רשומות למחיקה");
        }
      })
      .catch(() => {
        toast.error("שגיאה במחיקת הרשומה");
      });
  };

  const saveRow = (selectedRow: any) => {
    updateExpense(
      selectedRow.id,
      selectedRow.sort_date,
      selectedRow.group_id,
      selectedRow.expenses
    )
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה עודכנה בהצלחה");
          refreshGrid();
        } else {
          toast.warning("לא נמצאו רשומות לעדכון");
        }
      })
      .catch(() => {
        toast.error("שגיאה בעדכון רשומה");
      });
  };

  const saveExpense = (addedRow: any) => {
    insertExpense(addedRow.sort_date, addedRow.group_id, addedRow.expenses)
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה נוספה בהצלחה");
          refreshGrid();
        } else {
          toast.warning(
            "קיימת רשומה עם התאריך והקבוצה שבחרת. בכדי להוסיף רשומה חדשה, יש למחוק את הרשומה הישנה במסך רישום ההוצאות."
          );
        }
      })
      .catch(() => {
        toast.error("שגיאה בהוספת רשומה!!!");
      });
  };

  const columns = [
    {
      field: "id",
      title: "#",
      width: "5%",
      editable: false,
      type: "numeric",
    },

    {
      field: "group_id",
      title: "קבוצה",
      width: "25%",
      lookup: Object.keys(groups).length > 1 ? groups : false,
      render: (row: any) => {
        return groups[row.group_id];
      },
    },
    {
      field: "sort_date",
      title: "תאריך",
      width: "15%",
      type: "date",
    },
    {
      field: "expenses",
      title: "סכום",
      width: "15%",
      type: "numeric",
    },
    {
      width: "30%",
    },
  ];

  return (
    <CustomPage headerParameters={headerProps} pageName={"Home"}>
      <IonGrid>
        {VendorDetailsCxt &&
          VendorDetailsCxt.GeneralInfo != null &&
          selectedStartDate &&
          selectedEndDate && (
            <CustomMaterialTable
              rows={ExpensesMaintData}
              columns={columns}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              summaryTbl={sums}
              isEditable={true}
              onEdit={saveRow}
              onDelete={deleteRow}
              onAdd={saveExpense}
              title={"רישום הוצאות ROI"}
              isChangDefualteDate={true}
              onSearchClick={(startDate: Date, endDate: Date) => {
                setSelectedStartDate && setSelectedStartDate(startDate);
                setSelectedEndDate && setSelectedEndDate(endDate);
                // refreshGrid();
              }}
            />
          )}
      </IonGrid>
    </CustomPage>
  );
};

export default ExpensesMaint;
