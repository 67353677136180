import jsPDF from "jspdf";
import React from "react";
import { optionButtons } from "../components/utils/CustomElements/CustomeMoreOptions";

export interface HeaderParameters {
  isShowLogo?: boolean;
  isShowGreeting?: boolean;
  pdfDownloadPdf?: jsPDF;
  isPageHaveChanges?: boolean;
  isShowHamburger?: boolean;
  isShowBackButton?: boolean;
  isShowDeleteButton?: boolean;
  modalType?: "" | "modal" | "itemPreview" | undefined;
  isImagePreview?: boolean;
  isShowBusinessCardPreview?: boolean;
  isCloseModalBtn?: boolean;
  isShowEditButton?: boolean;
  isShowNotification?: boolean;
  numOfMultiSelected?: number | undefined;
  isHeaderDark?: boolean;
  isLogin?: boolean;
  authValues?: any;
  isBreadCrumbs?: boolean;
  deleteFunc?: () => void;
  closeModalFunc?: () => void;
  editBtnFunc?: () => void;
  onCancelMultiSelected?: () => void;
  moreOptionsButtons?: optionButtons[];
}

const HeaderContext = React.createContext<{
  headerParameters: HeaderParameters;
  initHeader: (headerParameters: HeaderParameters) => void;
}>({
  headerParameters: {},
  initHeader: () => {},
});

export default HeaderContext;
