import React, { useContext, useEffect, useState } from "react";

import { HeaderParameters } from "../data/Header-Context";
import CustomPage from "./utils/CustomElements/CustomPage";
import { IonGrid } from "@ionic/react";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import {
  getUsers,
  updateUser,
  addUser,
  getOrgs,
  updateUserOrgId,
} from "../services/UsersService";
import { toast } from "react-toastify";

import UserContext from "../data/AuthContextProvider";

const Permissions: React.FC = () => {
  const { authValues } = useContext(UserContext);
  const [adminUsers, setAdminUsers] = useState<any>([]);
  const [orgs, setOrgs] = useState<any>([]);
  const [normalUsers, setNormalUsers] = useState<any>([]);
  const [orgIdNew, setOrgIdNew] = useState<string>("");

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  const columns = [
    {
      title: "שם",
      field: "contactName",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "חברה",
      field: "orgName",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      //editable: false,
      editComponent: (row: any) => {
        return (
          <select
            value={row.rowData.orgId}
            onChange={(event) => onChange(event.target.value, row.rowData.id)}
          >
            <option value="-1">בחר</option>
            {orgs.map((org: any) => (
              <option value={org.runid} key={org.runid}>
                {org.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      title: "מזהה",
      field: "id",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
        textAlign: "right",
      },
      editable: false,
    },
    {
      title: "טלפון",
      field: "phone",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
        textAlign: "right",
      },
    },
    {
      title: "מייל",
      field: "email",
      cellStyle: {
        width: "8.4%",
        textAlign: "right",
      },
    },
    {
      title: "שם משתמש",
      field: "userName",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
  ];

  const refresh = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getUsers(true, orgId).then((result) => {
      setAdminUsers(result);
    });
    getUsers(false, orgId).then((result) => {
      setNormalUsers(result);
    });
  };

  const onChange = (orgId: string, id: string) => {
    if (id == undefined) {
      setOrgIdNew(orgId);
    }
    if (authValues && authValues.Id && id != undefined) {
      updateUserOrgId(authValues.Id.toString(), id, orgId)
        .then((result) => {
          if (result === "Success") {
            toast.info("רשומה עודכנה בהצלחה");
            refresh();
          } else {
            toast.warning("לא נמצאו רשומות לעדכון");
          }
        })
        .catch(() => {
          toast.error("שגיאה בעדכון רשומה");
        });
    }
  };
  const saveRow = (selectedRow: any) => {
    if (authValues && authValues.Id) {
      updateUser(
        authValues.Id.toString(),
        selectedRow.id,
        selectedRow.contactName,
        selectedRow.phone,
        selectedRow.email,
        selectedRow.userName
      )
        .then((result) => {
          if (result === "Success") {
            toast.info("רשומה עודכנה בהצלחה");
            refresh();
          } else {
            toast.warning("לא נמצאו רשומות לעדכון");
          }
        })
        .catch(() => {
          toast.error("שגיאה בעדכון רשומה");
        });
    }
  };

  const addAdminUser = (addedRow: any) => {
    addRow(addedRow, true);
  };

  const addNormalUser = (addedRow: any) => {
    addRow(addedRow, false);
  };

  const addRow = (addedRow: any, isAdmin: boolean) => {
    if (
      addedRow.contactName == undefined ||
      addedRow.phone == undefined ||
      addedRow.email == undefined ||
      addedRow.userName == undefined ||
      orgIdNew == "" ||
      orgIdNew == undefined
    ) {
      toast.warning(
        "אחד מהשדות ריק, יש למלא את כל השדות הרלוונטיים ליצירת משתמש"
      );
      return;
    }
    if (authValues && authValues.Id) {
      addUser(
        authValues.Id.toString(),
        addedRow.contactName,
        addedRow.phone,
        addedRow.email,
        addedRow.userName,
        isAdmin,
        orgIdNew
      )
        .then((result) => {
          if (result === "Success") {
            toast.info("רשומה נוספה בהצלחה");
            refresh();
          } else {
            toast.warning("משתמש לא נוצר במערכת, יש לפנות לתמיכה");
          }
        })
        .catch(() => {
          toast.error("שגיאה בהוספת רשומה!!!");
        });
    }
  };

  useEffect(() => {
    refresh();
    getOrgs().then((result) => {
      setOrgs(result);
    });
  }, []);

  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Permissions"}
      title="הרשאות משתמשים"
      // authValues = {authValues}
    >
      {authValues && authValues.Id && (
        <IonGrid>
          <CustomMaterialTable
            rows={adminUsers}
            columns={columns}
            onSearchClick={(startDate: Date, endDate: Date) => {}}
            // summaryTbl={sums}
            //gridActivities={gridActivities}
            title={"מנהלים"}
            loaderDuration={3000}
            isDateRange={false}
            isEditable={true}
            onEdit={saveRow}
            onAdd={addAdminUser}
            isFilterable={false}
            isDeleteHidden={true}
          />

          <CustomMaterialTable
            rows={normalUsers}
            columns={columns}
            onSearchClick={(startDate: Date, endDate: Date) => {}}
            // summaryTbl={sums}
            //gridActivities={gridActivities}
            title={"משתמשים"}
            loaderDuration={3000}
            isDateRange={false}
            isEditable={true}
            onEdit={saveRow}
            onAdd={addNormalUser}
            isFilterable={false}
            isDeleteHidden={true}
          />
        </IonGrid>
      )}
    </CustomPage>
  );
};

export default Permissions;
