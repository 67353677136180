import { useCallback, useState } from "react";

import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import { getVendorSettings } from "../services/VendorDetailsService";
import VendorDetailsContext, { GeneralInfo } from "./VendorDetails-context";

export const VendorDetailsProvider: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [GeneralInfo, setGeneralInfo] = useState<GeneralInfo>();

  const initVendorDetailsContext = useCallback(async () => {
    getVendorSettings("date").then((result: any) => {
      if (result) {
        var selectedPeriodID = result?.filter((obj: any) => {
          return obj.active === true;
        });
        if (selectedPeriodID && selectedPeriodID[0]) {
          setGeneralInfo({ DefaultPeriod: selectedPeriodID[0].id });
        } else {
          // set to default (חודש נוכחי)
          setGeneralInfo({ DefaultPeriod: "5" });
        }
      }
    });
  }, []);

  const setSelectedDate = useCallback(async (fromDate: Date, toDate: Date) => {
    setGeneralInfo({
      DefaultPeriod: "-1",
      SelectedFromDate: fromDate,
      SelectedToDate: toDate,
    });
  }, []);

  const setDefaultPeriod = (ID: any) => {
    setGeneralInfo({ DefaultPeriod: ID });
  };

  const setLoader = (loading: boolean) => {
    setLoading(loading);
  };
  // the store object
  let state = {
    GeneralInfo,
    loading,
    setLoader,
    initVendorDetailsContext,
    setDefaultPeriod,
    setSelectedDate,
  };

  // wrap the application in the provider with the initialized context
  return (
    <VendorDetailsContext.Provider value={state}>
      {props.children}
      <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
        duration={7000}
      ></CustomLoader>
    </VendorDetailsContext.Provider>
  );
};
