import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import UserContext from "../../data/AuthContextProvider";
import { HeaderParameters } from "../../data/Header-Context";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import Cookies from "universal-cookie";
import moment from "moment";

import VendorDetailsContext from "../../data/VendorDetails-context";
// import {
//   getChartData,
//   getVendorCard,
//   getAllVendors,
// } from "../../services/VendorDetailsService";
import {
  getPeriodData,
  getProductsPerformance,
  insertIntoBaseLine,
  getProductBaseLineData,
} from "../../services/DataServices";

import {
  IonCol,
  IonLabel,
  IonRow,
  IonGrid,
  IonCard,
  IonButton,
  IonIcon,
  IonItem,
} from "@ionic/react";
import {
  getDateFormat,
  getDateReverseFormat,
} from "../../utils-ts/GlobalDateFunctions";

import CustomAlert, {
  ButtonProperties,
} from "../../components/utils/CustomElements/CustomAlert";
import { config } from "../../Constants";
import { isPlatform } from "@ionic/core";
import { calendarOutline, addCircleOutline } from "ionicons/icons";
import CustomDateRangePicker from "../../components/utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import CustomModal from "../../components/utils/CustomElements/CustomModal/CustomModal";
import CustomMaterialTable from "../../components/utils/CustomElements/CustomDataGrid/CustomMaterialTable";

const Home: React.FC = () => {
  const { authValues, logout } = useContext(UserContext);

  const [periodData, setPeriodData] = useState<any>([]);
  const [productsPerformance, setProductsPerformance] = useState<any>([]);
  const [productBaseLineData, setProductBaseLineData] = useState<any>([]);

  const VendorDetailsCxt = useContext(VendorDetailsContext);
  //const [vendorCard, setVendorCard] = useState<any>([]);
  //const [chartData, setChartData] = useState<any>([]);
  //const [chartSelected, setChartSelected] = useState<string>("roi");
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  //const [selectedPeriodChart, setSelectedPeriodChart] = useState<string>("1");
  const [showPopUpLogOut, setShowPopUpLogOut] = useState(false);
  //const [vendors, setVendors] = useState<any>([]);
  //const [selectedVendor, setSelectedVendor] = useState<number>(-1);
  const [isDateRangeShow, setDateRangeShow] = useState<boolean>(false);
  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  const cookies = new Cookies();

  useEffect(
    () => {
      if (
        cookies.get("selectedStartDate") != undefined &&
        cookies.get("selectedEndDate") != undefined
      ) {
        const fromDate: Date = moment(
          cookies.get("selectedStartDate")
        ).toDate();
        const endDate: Date = moment(cookies.get("selectedEndDate")).toDate();
        setSelectedStartDate(fromDate);
        setSelectedEndDate(endDate);
      } else {
        // if (
        //   VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
        //   VendorDetailsCxt.GeneralInfo?.SelectedToDate
        // ) {
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());
        //}
      }
    },
    [
      // VendorDetailsCxt.GeneralInfo?.SelectedFromDate,
      // VendorDetailsCxt.GeneralInfo?.SelectedToDate,
    ]
  );

  useEffect(() => {
    if (
      authValues?.OrgId! &&
      selectedStartDate != undefined &&
      selectedEndDate != undefined
    ) {
      getHomeDetails();
    }

    cookies.set("selectedStartDate", selectedStartDate);
    cookies.set("selectedEndDate", selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);

  const getHomeDetails = () => {
    if (selectedStartDate && selectedEndDate) {
      getPeriodData(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        authValues?.OrgId!,
        "-1"
      ).then((result) => {
        setPeriodData(result);
      });
      getProductsPerformance(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        authValues?.OrgId!
      ).then((result) => {
        setProductsPerformance(undefined);
        setProductsPerformance(result);
      });

      refreshProductBaseLine();

      // getVendorCard(
      //   getDateReverseFormat(selectedStartDate),
      //   getDateReverseFormat(selectedEndDate)
      // ).then((result) => {
      //   setVendorCard(result);
      // getChartData(selectedPeriodChart, chartSelected).then((res) => {
      //   setChartData(res);
      //   VendorDetailsCxt.setLoader(false);
      // });
      // });
    }
  };
  // const getCampaigns = (selectedRow: any) => {
  //   console.log(selectedRow.runId);
  // };

  const refreshProductBaseLine = () => {
    getProductBaseLineData(authValues?.OrgId!).then((result) => {
      setProductBaseLineData(undefined);
      setProductBaseLineData(result);
    });
  };
  const insertToBaseLine = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    insertIntoBaseLine(orgId)
      .then((result) => {
        console.log(result);
        if (result !== -1) {
          if (result !== 0) {
            toast.info(result + " רשומות נוספו בהצלחה!");
            refreshProductBaseLine();
          } else {
            toast.warning("רשומות לא נוספו להיום");
          }
        } else {
          toast.error("שגיאה בהוספת רשומות!!!");
        }
      })
      .catch(() => {
        toast.error("שגיאה בהוספת רשומות!!!");
      });
  };
  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
    authValues: authValues,
  };

  const ErrorMessageButton: ButtonProperties[] = [
    {
      text: "לא",
      role: "no",
      handler: () => {
        setShowPopUpLogOut(false);
      },
    },
    {
      text: "כן",
      role: "yes",
      handler: () => {
        logout(() => {});
      },
    },
  ];

  const columns = [
    {
      title: "",
      field: "runId",
      cellStyle: {
        width: "50px",
        textAlign: "center",
        minWidth: "50px",
      },
      render: (row: any) => {
        return (
          <NavLink
            to={{
              pathname: `/Campaigns/${row.runId}`,
            }}
          >
            <IonIcon
              size="large"
              icon={addCircleOutline}
              slot="end"
              // onClick={() => {
              //   getCampaigns(row);
              // }}
            />
          </NavLink>
        );
      },
      filtering: false,
    },

    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "תקציב שהוקצה",
      field: "income",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "תקציב שנוצל",
      field: "cost",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "המרות",
      field: "conversions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "חשיפות",
      field: "impressions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "עלות ממוצעת להמרה",
      field: "costConv",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "כניסות לנכסים דיגיטליים",
      field: "entriesToDigitalAssets",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
  ];
  const columnsProductBaseLine = [
    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "15%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "תאריך הכנסה אחרון",
      field: "LastInsertDate",
      cellStyle: {
        width: "15%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "מתאריך",
      field: "FromDate",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "עד תאריך",
      field: "ToDate",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "AvgIncome",
      field: "AvgIncome",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgClicks",
      field: "AvgClicks",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgImpression",
      field: "AvgImpression",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgCtr",
      field: "AvgCtr",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "MinROI",
      field: "MinROI",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "MaxROI",
      field: "MaxROI",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgCost",
      field: "AvgCost",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgConversions",
      field: "AvgConversions",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgPageArrived",
      field: "AvgPageArrived",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgShoppingCart",
      field: "AvgShoppingCart",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
  ];

  // const dateSelectionHanlder = (date: any) => {
  //   setSelectedDate(date._d);
  // };

  return (
    <CustomPage
      headerParameters={headerProps}
      //title="תמונת מצב כללית"
      pageName={"dfgdfg"}
      authValues={authValues}
      fixedPage={true}
    >
      <CustomAlert
        isOpen={showPopUpLogOut}
        header={"הודעת מערכת"}
        message={"האם ברצונך להתנתק?"}
        onDismiss={() => {
          setShowPopUpLogOut(false);
        }}
        buttons={ErrorMessageButton}
      ></CustomAlert>
      {/* {authValues?.Email === "admin1@admin1" && (
        <IonRow>
          <IonCol size="1">
            <IonItem>
              <IonSelect
                defaultValue={-1}
                placeholder="בחר ספק"
                onIonChange={(e) => {
                  e.preventDefault();
                  if (
                    +e.detail.value !== +config.adminId &&
                    +e.detail.value !== +selectedVendor
                  ) {
                    setSelectedVendor(+e.detail.value);
                    VendorDetailsCxt.setLoader(true);
                    changeVendorByAdmin(
                      e.detail.value,
                      authValues?.Email!,
                      vendors.find(
                        (vendor: any) =>
                          vendor.vendor_id === parseInt(e.detail.value)
                      ).vendor_name,
                      authValues?.IsAdmin!,
                      authValues?.OrgId!
                    ).then((res: boolean) => {
                      if (res) {
                        getVendorDetails();
                      }
                    });
                  }
                }}
                value={selectedVendor}
              >
                {vendors &&
                  vendors.map((vendor: any) => {
                    return (
                      <IonSelectOption
                        key={vendor.vendor_id}
                        value={vendor.vendor_id}
                      >
                        {vendor.vendor_name}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
      )} */}
      {authValues?.Id !== config.adminId && (
        <IonGrid className="main-content" fixed={true}>
          <IonCard>
            <IonRow className="ion-no-margin ion-no-padding">
              <IonCol
                className="ion-no-margin ion-no-padding"
                style={{ display: "flex" }}
              >
                <h2 className="card-title large-title">אז איך הולך?</h2>
                <IonButton
                  size="small"
                  className="date-range-picker-btn"
                  onClick={() => {
                    setDateRangeShow(true);
                  }}
                >
                  <IonIcon size="small" icon={calendarOutline} slot="end" />
                  {selectedEndDate != undefined &&
                    selectedStartDate != undefined && (
                      <IonLabel>
                        {getDateFormat(selectedEndDate)} -
                        {getDateFormat(selectedStartDate)}
                      </IonLabel>
                    )}
                </IonButton>
              </IonCol>
            </IonRow>
            {/* <div className="card-item-divider"></div> */}

            {periodData && (
              <ul className="summery-list">
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#cbe3ff" }}
                >
                  <div className="extra-large-title">
                    {periodData.activeCampaign
                      ? periodData.activeCampaign
                      : "-"}
                  </div>
                  <div>קמפיינים פעילים כרגע</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <div className="extra-large-title">
                    {periodData.spendAmount
                      ? periodData.spendAmount.toLocaleString()
                      : "-"}
                  </div>
                  <div>הסכום שנוצל</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#d5edf9" }}
                >
                  <div className="extra-large-title">
                    {periodData.costConv ? periodData.costConv : "-"}
                  </div>
                  <div>עלות ממוצעת להמרה</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#c4d9e6" }}
                >
                  <div className="extra-large-title">
                    {periodData.conversions ? periodData.conversions : "-"}
                  </div>
                  <div>המרות</div>
                </li>
                {/* <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#cbe3ff" }}
                >
                  <div className="extra-large-title">
                    {periodData.entriesToDigitalAssets
                      ? periodData.entriesToDigitalAssets
                      : "-"}
                  </div>
                  <div>כניסות לנכסים הדיגיטליים</div>
                </li> */}
                {/* <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <div className="extra-large-title">
                    {periodData.convEntriesImpressions
                      ? periodData.convEntriesImpressions * 100 + "%"
                      : "-"}
                  </div>
                  <div>יחס המרה כניסות/המרות</div>
                </li> */}
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <div className="extra-large-title">
                    {periodData.clicks
                      ? periodData.clicks.toLocaleString()
                      : "-"}
                  </div>
                  <div>קליקים</div>
                </li>
                <li
                  className="summery-list-item"
                  style={{ backgroundColor: "#d5edf9" }}
                >
                  <div className="extra-large-title">
                    {periodData.impressions
                      ? periodData.impressions.toLocaleString()
                      : "-"}
                  </div>
                  <div>חשיפות</div>
                </li>
              </ul>
            )}
          </IonCard>
          <IonCard className="card-container">
            <IonRow className="ion-align-items-center">
              <IonCol className="ion-no-margin ion-no-padding card-title-container bold-text large-title ">
                ביצועי מוצרים
              </IonCol>
            </IonRow>
            {productsPerformance && (
              <CustomMaterialTable
                rows={productsPerformance}
                columns={columns}
                title={""}
                loaderDuration={3000}
                isDateRange={false}
                isFilterable={true}
                pageSize={productsPerformance.length}
                className="homeGrid"
              />
            )}
          </IonCard>
          <IonCard>
            <IonRow className="ion-no-margin ion-no-padding">
              <IonCol style={{ direction: "ltr" }}>
                <IonButton onClick={() => insertToBaseLine()}>
                  הכנס בוחן ביצועים אחרון
                </IonButton>
              </IonCol>
            </IonRow>
            {productBaseLineData && (
              <CustomMaterialTable
                rows={productBaseLineData}
                columns={columnsProductBaseLine}
                title={"בוחן ביצועים אחרון עבור כל מוצר"}
                loaderDuration={3000}
                isDateRange={false}
                isFilterable={false}
                pageSize={productBaseLineData.length}
              />
            )}
          </IonCard>
          {/*
          <IonCard className="card-container">
            <IonRow className="ion-align-items-center">
              <IonCol className="ion-no-margin ion-no-padding card-title-container">
                <IonSelect
                  value={chartSelected}
                  onIonChange={(e) => {
                    setChartSelected(`${e.detail.value}`);
                    VendorDetailsCxt.setLoader(true);
                    getChartData(selectedPeriodChart, `${e.detail.value}`).then(
                      (res) => {
                        setChartData(res);
                        VendorDetailsCxt.setLoader(false);
                      }
                    );
                  }}
                  color="light"
                  cancelText="ביטול"
                  okText="אישור"
                  interface="alert"
                  interfaceOptions={{ showBackdrop: false }}
                  placeholder="בחר מרשימה"
                  title="בחר מרשימה"
                  className="card-title large-title"
                >
                  {vendorCard &&
                    vendorCard
                      .filter((m: any) => m.field_desc !== "")
                      .map((m: any, index: any) => {
                        return (
                          <IonSelectOption key={index} value={m.field_desc}>
                            {`סיכום ${m.field_name}`}
                          </IonSelectOption>
                        );
                      })}
                </IonSelect>
                {!isMobile ? (
                  <IonRadioGroup
                    value={selectedPeriodChart}
                    className="title-select-list"
                    onIonChange={(e) => {
                      setSelectedPeriodChart(e.detail.value);
                      VendorDetailsCxt.setLoader(true);
                      getChartData(e.detail.value, chartSelected).then(
                        (res) => {
                          setChartData(res);
                          VendorDetailsCxt.setLoader(false);
                        }
                      );
                    }}
                  >
                    <IonItem className="ion-no-margin" lines="none" mode="md">
                      <IonLabel className="ion-no-margin">שנה אחרונה</IonLabel>
                      <IonRadio
                        className="ion-no-margin"
                        slot="start"
                        value="1"
                      />
                    </IonItem>
                    <IonItem className="ion-no-margin" lines="none" mode="md">
                      <IonLabel className="ion-no-margin">
                        שבועיים אחרונים
                      </IonLabel>
                      <IonRadio
                        className="ion-no-margin"
                        slot="start"
                        value="2"
                      />
                    </IonItem>
                  </IonRadioGroup>
                ) : (
                  <IonSelect
                    value={selectedPeriodChart}
                    color="light"
                    cancelText="ביטול"
                    okText="אישור"
                    interfaceOptions={{ showBackdrop: false }}
                    placeholder="בחר מרשימה"
                    onIonChange={(e) => {
                      setSelectedPeriodChart(e.detail.value);
                      VendorDetailsCxt.setLoader(true);
                      getChartData(e.detail.value, chartSelected).then(
                        (res) => {
                          setChartData(res);
                          VendorDetailsCxt.setLoader(false);
                        }
                      );
                    }}
                  >
                    <IonSelectOption value="1">שנה אחרונה</IonSelectOption>
                    <IonSelectOption value="2">שבועיים אחרונים</IonSelectOption>
                  </IonSelect>
                )}
              </IonCol>
            </IonRow>
            <div className="card-item-divider"></div>

            //React Chart: https://devexpress.github.io/devextreme-reactive/react/chart/docs/guides/getting-started/ 
            <IonRow>
              <IonCol className="graph-container">
                <Chart data={chartData} height={!isMobile ? 500 : 250}>
                  <ArgumentAxis />
                  <ValueAxis />
                  <LineSeries valueField="value" argumentField="argument" />
                </Chart>
              </IonCol>
            </IonRow>
          </IonCard>
          */}
        </IonGrid>
      )}

      <CustomModal
        pageName={"DateRangePicker"}
        headerParameters={headerParameters}
        closeModal={() => {
          setDateRangeShow(false);
        }}
        isOpen={isDateRangeShow}
        title="בחר טווח תאריכים"
      >
        <CustomDateRangePicker
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setDateRangeShow(false);
            //VendorDetailsCxt.setLoader(true);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            //VendorDetailsCxt.setSelectedDate(startDate, endDate);
            // getVendorDetails();
          }}
        ></CustomDateRangePicker>
      </CustomModal>
    </CustomPage>
  );
};
export default Home;
