import React, { useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsClicksStatisticsRaw } from "../services/VendorDetailsService";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
// import CustomDataGrid from "./utils/CustomElements/CustomDataGrid/CustomDataGrid";
// import { GridColDef } from "@material-ui/data-grid";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { isPlatform } from "@ionic/react";
import { isMobile } from "react-device-detect";

const ActivityRaw: React.FC<{
  show: boolean;
  closeModal: (isDeleted: boolean) => void;
  referer_id: string;
  cat_code: string;
  city_code: string;
  city_title: string;
  startDate: Date;
  endDate: Date;
}> = (props) => {
  const [ActivityRaw, setActivityRaw] = useState<any>([]);
  const title = !isMobile
    ? props.city_title + " > תנועה גולמית"
    : "תנועה גולמית";

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.startDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(props.endDate);

  useEffect(() => {
    getVendorsClicksStatisticsRaw(
      props.referer_id,
      props.cat_code,
      props.city_code,
      getDateReverseFormat(selectedStartDate),
      getDateReverseFormat(selectedEndDate)
    ).then((result) => {
      setActivityRaw(result);
    });
  }, [selectedEndDate, selectedStartDate]);

  const columns = [
    {
      field: "click_id",
      title: "מפתח חשיפה",
      align: "right",
    },
    {
      field: "app_id",
      title: "פלטפורמה",
      align: "right",
    },
    {
      field: "click_date",
      title: "תאריך הקלקה",
      align: "right",
    },
    {
      field: "click_type_desc",
      title: "סוג איוונט",
      align: "right",
    },
    {
      field: "ip_address",
      title: "IP של משתמש קצה",
      align: "right",
    },
    {
      field: "device_type",
      title: "סוג מכשיר",
      align: "right",
    },
    {
      field: "member_id",
      title: "מפתח לקוח",
      align: "right",
    },
    {
      field: "cat_desc",
      title: "קטגוריה",
      align: "right",
    },
    {
      field: "referer_desc",
      title: "מפנה",
      align: "right",
    },
    {
      field: "is_blacklisted",
      title: "רשימה שחורה",
      align: "right",
    },
    {
      field: "is_click_fraud",
      title: "הונאה",
      align: "right",
    },
    {
      field: "is_above_budget",
      title: "מעל תקציב",
      align: "right",
    },
    {
      field: "price",
      title: "מחיר",
      align: "right",
    },
    {
      field: "package_type_desc",
      title: "חבילה",
      align: "right",
    },
    {
      field: "origin_url",
      title: "כתובת מקור",
      align: "right",
    },
    {
      field: "request_header",
      title: "פרמטרים בכתובת",
      align: "right",
    },
  ];

  return (
    <CustomModal
      pageName={"TrafficSourcePage"}
      headerParameters={headerParameters}
      title={isMobile ? props.city_title + " > " + title : ""}
      closeModal={() => {
        props.closeModal(false);
      }}
      isOpen={props.show}
    >
      <CustomMaterialTable
        rows={ActivityRaw}
        columns={columns}
        onSearchClick={(startDate: Date, endDate: Date) => {
          setSelectedStartDate && setSelectedStartDate(startDate);
          setSelectedEndDate && setSelectedEndDate(endDate);
        }}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        title={title}
      />
    </CustomModal>
  );
};

export default ActivityRaw;
