import { IonCol, IonGrid, IonPage, IonRow, isPlatform } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import HeaderContext, { HeaderParameters } from "../../../data/Header-Context";
import CustomContent from "./CustomContent";
import CustomPageTitle from "./CustomPageTitle/CustomPageTitle";
// import { imageWhite } from "../../../images/index";
import "../../../theme/avatar.scss";
import CustomAddFloatingButton from "./CustomAddFloatingButton";
import CustomDatePicker from "./CustomDatePicker/CustomDatePicker";
import { getDateFormat } from "../../../utils-ts/GlobalDateFunctions";
import { isMobile } from "react-device-detect";

export interface floatingButton {
  icon: string;
  title: string;
  onClick: () => void;
}

const CustomPage: React.FC<{
  title?: string;
  subTitle?: string;
  pageName: string; //send the  parent function name
  headerParameters: HeaderParameters;
  logoSrc?: string;
  logoClickFunc?: () => void;
  floatingButton?: floatingButton;
  fixedPage?: boolean;
  authValues?: any;
}> = (props) => {
  const HeaderCtx = useContext(HeaderContext);
  useEffect(() => {
    if (props.headerParameters) {
      HeaderCtx.initHeader(props.headerParameters);
    }
  });
  const titleWrapperWidthHandler = () => {
    let titleWidthSize = "10";

    return titleWidthSize;
  };

  return (
    <IonPage>
      <CustomContent
        isRefreshPage={true}
        fullscreen={true}
        isLogin={props.headerParameters.isLogin}
      >
        {/* {!props.headerParameters.isLogin && props.title && (
          <div className="wrapper"></div>
        )} */}
        {isMobile && props.floatingButton && (
          <CustomAddFloatingButton
            onclick={() => {
              props.floatingButton?.onClick();
            }}
            title={props.floatingButton?.title}
            icon={props.floatingButton?.icon}
          ></CustomAddFloatingButton>
        )}
        <IonGrid fixed={props.fixedPage || props.headerParameters.isLogin}>
          <IonRow
            className={"ion-align-items-center ion-justify-content-between"}
          >
            {props.title && (
              <IonCol
                className={"title-wrapper"}
                size-md="12"
                size={titleWrapperWidthHandler()}
              >
                <CustomPageTitle
                  pageName={props.pageName}
                  titleClass="white-text xl-p"
                  title={props.title}
                  subTitle={props.subTitle}
                  subTitleClass="ion-no-margin"
                  floatingButton={!isMobile ? props.floatingButton : undefined}
                  pdfDownloadPdf={props.headerParameters.pdfDownloadPdf}
                  moreOptionsButtons={props.headerParameters.moreOptionsButtons}
                  isLogin={props.headerParameters.isLogin}
                />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>

        {props.children}
      </CustomContent>
    </IonPage>
  );
};

export default CustomPage;
