import React, { ComponentProps, forwardRef, useState } from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
// import { object, string } from "yup";

import {
  Controller,
  Control,
  FieldError,
  NestDataObject,
} from "react-hook-form";

import {
  validatePatterns,
  ErrorMessage,
} from "../../../utils-ts/GlobalValidationFunctions";

type IonInputProps = ComponentProps<typeof IonInput>;
type inputRef = HTMLIonInputElement;

interface InputProps extends IonInputProps {
  name: string;
  control?: Control;
  patternType?: "facebook" | "youtube";
  label?: string;
  isHideCounter?: boolean;
  pageName?: string;
  errors?: NestDataObject<Record<string, any>, FieldError>;
}

const CustomInput = forwardRef<inputRef, InputProps>(
  ({ label, name, patternType, control, errors, ...props }, ref) => {
    const isClearInput = props.clearInput ? props.clearInput : true;
    const [numOfCharacter, setNumOfCharacter] = useState(() => {
      return props.value ? props.value.toString().length : 0;
    });

    return (
      <React.Fragment>
        <IonItem
          mode="md"
          color={props.pageName !== "CommentReplay" ? "light" : ""}
          lines="none"
          className={`
            ${
              props.pageName == "CommentReplay"
                ? "input-item-comment"
                : "input-item"
            }
            ${errors && errors[name] != null ? "item-invalid" : ""}`}
        >
          {label && (
            <IonLabel position="floating">
              {props.required ? label + "*" : label}
            </IonLabel>
          )}
          <Controller
            as={
              <IonInput
                clearInput={isClearInput}
                mode="md"
                {...props}
                min="0"
                inputmode={
                  props.type === "number" || props.type === "tel"
                    ? "numeric"
                    : undefined
                }
                pattern={
                  props.type === "number" || props.type === "tel"
                    ? "[0-9]*"
                    : props.pattern
                }
                ref={ref}
              ></IonInput>
            }
            name={name}
            control={control}
            defaultValue={props.value}
            rules={{
              required: {
                value: props.required ? props.required : false,
                message: ErrorMessage("required"),
              },
              minLength: {
                value: props.min ? props.min : "undefined",
                message: ErrorMessage("minLength", props.min),
              },
              maxLength: {
                value: props.max ? props.max : "undefined",
                message: ErrorMessage("maxLength", props.max),
              },
              validate: (value) => {
                if (value != "") {
                  if (patternType) {
                    return validatePatterns(value, patternType);
                  } else if (props.type) {
                    return validatePatterns(value, props.type);
                  }
                }
              },
            }}
            onChangeName="onIonChange"
            onBlur={(e) => {
              const newval = e[0].currentTarget.value;
              if (
                (props.type === "url" || patternType) &&
                newval != "" &&
                newval.includes("http") === false
              ) {
                e[0].currentTarget.value = "http://" + e[0].currentTarget.value;
              }
            }}
            onChange={(selected) => {
              // add http to all links by default
              if (selected[0].detail.value != null) {
                setNumOfCharacter(selected[0].detail.value.length);
              }
              return selected[0].detail.value;
            }}
          />
        </IonItem>
        <div className="input-alerts">
          {errors && errors[name] != null && (
            <p className="input-error">{(errors as any)[name].message}</p>
          )}
          {props.isHideCounter === undefined && props.maxlength && (
            <p className="small-p input-counter">
              {numOfCharacter}/{props.maxlength}
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
);

export default CustomInput;
