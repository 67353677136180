import { isPlatform } from "@ionic/core";
import { isMobile } from "react-device-detect";

export const convertTimeStampToDate = (timeStamp: string) => {
  const returnDate = new Date(
    +timeStamp.replace(/\/Date\((\d+)\)\//, "$1")
  ).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return returnDate;
};

export const getTimeFotmat = (houre: number, minut: number) => {
  return ("0" + houre).slice(-2) + ":" + ("0" + minut).slice(-2);
};

export const getDateFormat = (date: Date) => {
  let shortYear = !isMobile
    ? date.getFullYear()
    : date.getFullYear().toString().substr(-2);
  let seperator = !isMobile ? "/" : ".";
  return (
    date.getDate() + seperator + (date.getMonth() + 1) + seperator + shortYear
  );
};

export const getDateReverseFormat = (date: Date) => {
  return (
    date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
  );
};

export const getTimeObjFromIsoString = (value: string) => {
  let time = null;
  if (value.indexOf("T") > -1) {
    time = new Date(value);
    time = getTimeFotmat(time.getHours(), +time.getMinutes());
  } else {
    time = value;
  }
  return time;
};

export const getDateObjFromIsoString = (value: string) => {
  let date = null;
  if (value.indexOf("T") > -1) {
    date = new Date(value);
    date = getDateFormat(date);
  } else {
    date = value;
  }
  return date;
};

export const dateToISOString = (date: string) => {
  if (date) {
    return new Date(parseInt(date.substr(6))).toISOString();
  } else {
    return date;
  }
};

export const addDaysToDate = (date: Date, days: number) => {
  return new Date(date.setDate(date.getDate() + days));
};

export const addMonthsToDate = (date: Date, months: number) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const monthDiff = (d1: any, d2: any) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
