import { IonGrid } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsMain } from "../services/VendorDetailsService";
// import ActivityByDate from "./ActivityByDate";
import ActivityByCatXArea from "./ActivityByCatXArea";
import EventsDetails from "./EventsDetails";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import { defaultStaticRanges } from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import VendorDetailsContext from "../data/VendorDetails-context";
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";

const TrafficSource: React.FC = () => {
  const VendorDetailsCxt = useContext(VendorDetailsContext);

  const [VendorsMain, setVendorsMain] = useState<any>([]);
  // const [sums, setSums] = useState<any>([]);
  // const [showActivityByDateGrid, setShowActivityByDateGrid] = useState(false);
  const [showActivityByCatXAreaGrid, setShowActivityByCatXAreaGrid] =
    useState(false);
  const [showEventsDetailsGrid, setShowEventsDetailsGrid] = useState(false);

  const [selected_referer_id, setSelectedRefererId] = useState("-1");
  const [selected_desc, setSelectedDesc] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  useEffect(() => {
    if (VendorDetailsCxt.GeneralInfo == null) {
      VendorDetailsCxt.initVendorDetailsContext();
    }
  }, []);

  useEffect(() => {
    if (
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod &&
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod !== "-1"
    ) {
      setSelectedStartDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .startDate
      );
      setSelectedEndDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .endDate
      );
    }
  }, [VendorDetailsCxt.GeneralInfo?.DefaultPeriod]);

  useEffect(() => {
    if (
      VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
      VendorDetailsCxt.GeneralInfo?.SelectedToDate
    ) {
      setSelectedStartDate(VendorDetailsCxt.GeneralInfo?.SelectedFromDate);
      setSelectedEndDate(VendorDetailsCxt.GeneralInfo?.SelectedToDate);
    }
  }, [
    VendorDetailsCxt.GeneralInfo?.SelectedFromDate,
    VendorDetailsCxt.GeneralInfo?.SelectedToDate,
  ]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      getVendorsMain(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate)
      ).then((result) => {
        setVendorsMain(result);
      });
    }
  }, [selectedEndDate, selectedStartDate]);

  const activityFunc = (selectedRow: any, btnId: string) => {
    setSelectedRefererId(selectedRow.id.toString());
    setSelectedDesc(selectedRow.description);
    switch (btnId) {
      // case "hour":
      //   setShowActivityByDateGrid(true);
      //   break;
      case "category":
        setShowActivityByCatXAreaGrid(true);
    }
  };

  const gridActivities = [
    {
      icon: () => <WidgetsOutlinedIcon />,
      btnId: "category",
      tooltip: "לפי קטגוריה",
      onClick: activityFunc,
    },
  ];

  const columns = [
    {
      title: "תאור",
      field: "description",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
      },
    },
    {
      title: "מקור תנועה",
      field: "id",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "חיפושים",
      field: "Search_All",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "הכנסות",
      field: "amountCharged",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "הוצאות",
      field: "expenses",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },

    {
      title: "כניסות",
      field: "visits",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "איבנטים",
      field: "tot_events",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
      render: (row: any) => {
        const eventsDetailsFunc = () => {
          setSelectedRefererId(row.id.toString());
          setSelectedDesc(row.description);
          setShowEventsDetailsGrid(true);
        };
        return row.tot_events > 0 ? (
          <button className="link-button" onClick={eventsDetailsFunc}>
            {row.tot_events}
          </button>
        ) : (
          row.tot_events
        );
      },
    },
    {
      title: "חשיפות",
      field: "tot_clicks",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "חשיפות לחיוב",
      field: "tot_clicks_charged",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "מעל תקציב",
      field: "above_budget",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "פרודים",
      field: "fraud",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
    {
      title: "רשימה שחורה",
      field: "black_list",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },

    {
      field: "roi",
      title: "ROI",
      type: "numeric",
      cellStyle: {
        width: "8.4%",
      },
    },
  ];

  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Home"}
      // authValues = {authValues}
    >
      <IonGrid>
        {VendorDetailsCxt &&
          VendorDetailsCxt.GeneralInfo != null &&
          selectedStartDate &&
          selectedEndDate && (
            <CustomMaterialTable
              rows={VendorsMain}
              columns={columns}
              onSearchClick={(startDate: Date, endDate: Date) => {
                setSelectedEndDate(endDate);
                setSelectedStartDate(startDate);
              }}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              // summaryTbl={sums}
              gridActivities={gridActivities}
              title={"פירוט נתונים"}
              loaderDuration={30000}
              isChangDefualteDate={true}
              isDateRange={true}
            />
          )}

        {/* </CustomModal> */}
        {/* {showActivityByDateGrid && (
          <ActivityByDate
            show={showActivityByDateGrid}
            closeModal={(isDeleted: boolean) => {
              setShowActivityByDateGrid(false);
            }}
            referer_id={selected_referer_id}
            referer_desc={selected_desc}
            forDate={selectedStartDate}
          ></ActivityByDate>
        )} */}
        {showActivityByCatXAreaGrid && (
          <ActivityByCatXArea
            referer_id={selected_referer_id}
            referer_desc={selected_desc}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            show={showActivityByCatXAreaGrid}
            closeModal={(isDeleted: boolean) => {
              setShowActivityByCatXAreaGrid(false);
            }}
          ></ActivityByCatXArea>
        )}
        {showEventsDetailsGrid && (
          <EventsDetails
            referer_id={selected_referer_id}
            referer_desc={selected_desc}
            cat_code=""
            area_code=""
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            show={showEventsDetailsGrid}
            closeModal={(isDeleted: boolean) => {
              setShowEventsDetailsGrid(false);
            }}
          ></EventsDetails>
        )}
      </IonGrid>
    </CustomPage>
  );
};

export default TrafficSource;
