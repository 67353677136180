import {
  ActionSheetButton,
  IonButton,
  IonIcon,
  IonPopover,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import React, { useEffect, useState } from "react";

export interface optionButtons {
  title: string;
  icon?: string;
  customClass?: string;
  onClick: () => void;
}

const CustomeMoreOptions: React.FC<{
  options: optionButtons[];
}> = (props) => {
  const [isShowOptions, setIsShowOptions] = useState({
    showPopover: false,
    event: undefined,
  });
  let optBtn: ActionSheetButton[] = [];
  useEffect(() => {});

  return (
    <React.Fragment>
      {props.options && props.options.length > 0 && (
        <IonButton
          fill="clear"
          className="more-options-button"
          onClick={(e: any) => {
            e.persist();
            setIsShowOptions({ showPopover: true, event: e });
          }}
        >
          <IonIcon color="" icon={ellipsisVertical}></IonIcon>
        </IonButton>
      )}
      <IonPopover
        cssClass="more-options-popover"
        mode="md"
        event={isShowOptions.event}
        isOpen={isShowOptions.showPopover}
        onDidDismiss={() =>
          setIsShowOptions({ showPopover: false, event: undefined })
        }
      >
        {props.options.map((op, index) => {
          return (
            <IonButton
              key={index}
              fill="clear"
              className={op.customClass ? op.customClass : ""}
              onClick={() => {
                op.onClick();
                setIsShowOptions({ showPopover: false, event: undefined });
              }}
            >
              {op.icon && <IonIcon icon={op.icon} />}
              {op.title}
            </IonButton>
          );
        })}
      </IonPopover>
    </React.Fragment>
  );
};
export default CustomeMoreOptions;
