import { IonLoading } from "@ionic/react";
import React, { FC, ComponentProps } from "react";

import "react-toastify/dist/ReactToastify.css";
type CustomLoaderProps = ComponentProps<typeof IonLoading>;

interface loaderProps extends CustomLoaderProps {}

const CustomLoader: FC<loaderProps> = ({ ...props }) => {
  return (
    <IonLoading duration={props.duration ? props.duration : 4000} {...props} />
  );
};

export default CustomLoader;
