// import { IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { HeaderParameters } from "../data/Header-Context";
import { getVendorsClicksStatisticsCatByHour } from "../services/VendorDetailsService";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { isPlatform } from "@ionic/react";
import { isMobile } from "react-device-detect";

const ActivityByArea: React.FC<{
  show: boolean;
  closeModal: (isDeleted: boolean) => void;
  referer_id: string;
  cat_code: string;
  cat_title: string;
  forDate: Date;
}> = (props) => {
  const [ActivityCatHour, setActivityCatHour] = useState<any>([]);

  const headerParameters: HeaderParameters = {
    modalType: "modal",
    isBreadCrumbs: true,
  };

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.forDate
  );
  //   const [selected_city_code, setSelectedCityCode] = useState("-1");
  //   const [showActivityRawGrid, setShowActivityRawGrid] = useState(false);
  const title = !isMobile
    ? props.cat_title + " > פעילות שעתית לפי קטגוריה"
    : "פעילות שעתית לפי קטגוריה";
  // const [selectedCatTitle, setSelectedCatTitle] = useState("");
  const [sums, setSums] = useState<any>([]);

  useEffect(() => {
    getVendorsClicksStatisticsCatByHour(
      props.referer_id,
      props.cat_code,
      getDateReverseFormat(selectedStartDate),
      false
    ).then((result) => {
      getVendorsClicksStatisticsCatByHour(
        props.referer_id,
        props.cat_code,
        getDateReverseFormat(selectedStartDate),
        true
      ).then((result) => {
        setSums({ data: result });
      });
      setActivityCatHour(result);
    });
  }, [selectedStartDate]);

  //   const activityRawFunc = (selectedRow: any) => {
  //     setSelectedCityCode(selectedRow.area_code.toString());
  //     setSelectedCatTitle(selectedRow.area_desc);
  //     setShowActivityRawGrid(true);
  //   };

  const columns = [
    {
      field: "id",
      title: "מפתח",
      width: 30,
      align: "right",
      type: "numeric",
    },
    {
      field: "click_hour",
      title: "שעת ביצוע",
      width: 100,
      align: "right",
    },
    {
      field: "tot_events_count",
      title: "איבנטים",
      width: 100,
      align: "right",
      type: "numeric",
    },
    {
      field: "tot_clicks_count",
      title: "חשיפות",
      width: 100,
      align: "right",
      type: "numeric",
    },
    {
      field: "clicks_charged",
      title: "חשיפות לחיוב",
      width: 100,
      align: "right",
      type: "numeric",
    },
    {
      field: "amount_charged",
      title: "הכנסות",
      width: 100,
      align: "right",
      type: "numeric",
    },
    {
      field: "clicks_above_budget",
      title: "מעל תקציב",
      width: 100,
      align: "right",
      type: "numeric",
    },
    {
      field: "clicks_frauds",
      title: "הונאה",
      width: 150,
      align: "right",
      type: "numeric",
    },
    {
      field: "clicks_black_list",
      title: "רשימה שחורה",
      width: 150,
      align: "right",
      type: "numeric",
    },
  ];

  return (
    <React.Fragment>
      <CustomModal
        pageName={"TrafficSourcePage"}
        headerParameters={headerParameters}
        title={isMobile ? props.cat_title + " > " + title : ""}
        closeModal={() => {
          props.closeModal(false);
        }}
        isOpen={props.show}
      >
        <CustomMaterialTable
          rows={ActivityCatHour}
          columns={columns}
          onSearchClick={(startDate: any) => {
            const date = startDate._d ? startDate._d : startDate;
            setSelectedStartDate && setSelectedStartDate(date);
          }}
          startDate={selectedStartDate}
          summaryTbl={sums}
          title={title}
          isDateRange={false}
          dateType="date-picker"
        />
      </CustomModal>
      {/* {showActivityRawGrid && (
        <ActivityRaw
          referer_id={props.referer_id}
          cat_code={props.cat_code}
          city_code={selected_city_code}
          city_title={props.cat_title + " > " + selectedCatTitle}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          show={showActivityRawGrid}
          closeModal={(isDeleted: boolean) => {
            setShowActivityRawGrid(false);
          }}
        ></ActivityRaw>
      )} */}
    </React.Fragment>
  );
};

export default ActivityByArea;
