import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import moment from "moment";
import React, { ComponentProps, useState } from "react";
import { DateRangePicker } from "react-date-range";
import he from "date-fns/locale/he";
import { isMobile } from "react-device-detect";

// import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./CustomDateRangePicker.scss";
import { toast } from "react-toastify";
import { IonButton } from "@ionic/react";
import { isPlatform } from "@ionic/core";

//Documentation for DateRangePicker:
//https://www.npmjs.com/package/react-date-range

type DateRangePickerComponentProps = ComponentProps<typeof DateRangePicker>;

interface DateRangePickerProps extends DateRangePickerComponentProps {
  onDateChange?: (startDate: Date, endDate: Date) => void;
  limitedDates?: Number;
  onSearchClick?: (startDate: Date, endDate: Date) => void;
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const defaultStaticRanges = [
  {
    label: "היום",
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday,
    isSelected() {
      return false;
    },
  },
  {
    label: "אתמול",
    startDate: defineds.startOfYesterday,
    endDate: defineds.endOfYesterday,
    isSelected() {
      return false;
    },
  },

  {
    label: "השבוע",
    startDate: defineds.startOfWeek,
    endDate: defineds.endOfWeek,
    isSelected() {
      return false;
    },
  },
  {
    label: "שבוע שעבר",
    startDate: defineds.startOfLastWeek,
    endDate: defineds.endOfLastWeek,
    isSelected() {
      return false;
    },
  },
  {
    label: "החודש",
    startDate: defineds.startOfMonth,
    endDate: defineds.endOfMonth,
    isSelected() {
      return true;
    },
  },
  {
    label: "חודש שעבר",
    startDate: defineds.startOfLastMonth,
    endDate: defineds.endOfLastMonth,
    isSelected() {
      return false;
    },
  },
];

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  ...props
}) => {
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]);

  const customRanges: any = [];
  defaultStaticRanges.map((cutsomRange) =>
    customRanges.push({
      label: cutsomRange.label,
      range: () => ({
        startDate: cutsomRange.startDate,
        endDate: cutsomRange.endDate,
      }),
      isSelected() {
        return cutsomRange.startDate === state[0].startDate;
      },
    })
  );

  return (
    <React.Fragment>
      <DateRangePicker
        calendarFocus="backwards"
        onChange={(item: any) => {
          if (
            props.limitedDates &&
            !moment(item.selection.endDate).isSameOrBefore(
              moment(item.selection.startDate).add(+props.limitedDates, "days")
            )
          ) {
            toast.error(
              "טווח מקסימלי של " + (+props.limitedDates + 1) + " ימים"
            );
          } else {
            setState([
              {
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
                key: "selection",
              },
            ]);
          }
        }}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={!isMobile ? 2 : 1}
        ranges={state}
        direction={"horizontal"}
        locale={he}
        renderStaticRangeLabel={(opt: any) => {
          return (
            <span>
              <i>{opt.label}</i>
            </span>
          );
        }}
        staticRanges={customRanges}
        inputRanges={[]}
        {...props}
      />
      <IonButton
        onClick={() => {
          if (state[0].startDate && state[0].endDate && props.onSearchClick) {
            props.onSearchClick(state[0].startDate, state[0].endDate);
          }
        }}
      >
        חפש
      </IonButton>
    </React.Fragment>
  );
};
export default CustomDateRangePicker;
