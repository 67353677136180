import { Capacitor, Plugins } from "@capacitor/core";
import firebase from "firebase/app";
import "firebase/messaging";
import {
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from "@capacitor/core";

const { PushNotifications } = Plugins;

const config = {
  apiKey: "AIzaSyCM9y506tN7Ua_3t9dGGBORvkS08fAbI2s",
  authDomain: "easybusiness-e4b48.firebaseapp.com",
  databaseURL: "https://easybusiness-e4b48.firebaseio.com",
  projectId: "easybusiness-e4b48",
  storageBucket: "easybusiness-e4b48.appspot.com",
  messagingSenderId: "131891839336",
  appId: "1:131891839336:web:c48ecb9f3384eccd1196dd",
  measurementId: "G-EM2PZ8S5Q0",
};

// const INITIAL_STATE = {
//   notifications: [
//     {
//       id: "id",
//       title: "Test Push",
//       body: "This is my first push notification",
//     },
//   ],
// };
// const [state, setState] = useState(INITIAL_STATE);

if (
  Capacitor.getPlatform() !== "android" &&
  Capacitor.getPlatform() !== "ios"
) {
  firebase.initializeApp(config);
}

export const getNotificationToken = () =>
  new Promise((resolve, reject) => {
    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      pushNativeApp()
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          resolve("");
        });
    } else {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch(() => {
          resolve("");
        });
    }
  });

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    const messaging = firebase.messaging();

    messaging
      .getToken()
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch(() => {
        resolve("");
      });
  });

export const pushNativeApp = () =>
  new Promise((resolve, reject) => {
    // Register with Apple / Google to receive push via APNS/FCM
    // PushNotifications.register();

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    // alert("pushNativeApp");
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On succcess, we should be able to receive notifications
    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        // alert(token.value);
        resolve(token.value);
      }
    );

    // Some issue with your setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      // alert("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotification) => {
        window.location.href = notification.notification.data.Url;

        // alert(notification.body);
        // let notif = state.notifications;
        // notif.push({ id: notification.id, title: notification.title, body: notification.body })
        // setState({
        //   notifications: notif
        // })
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: PushNotificationActionPerformed) => {
        window.location.href = notification.notification.data.Url;

        // let notif = state.notifications;
        // notif.push({ id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body })
        // setState({
        //   notifications: notif
        // })
      }
    );
  });
