import { fetchRequest } from "../utils-ts/fetchRequest";
import { config } from "../Constants";

export const getPeriodData = (
  fromDate: string,
  toDate: string,
  orgId: string,
  productId: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
      orgId: orgId,
      productId: productId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getPeriodData`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getProducts = (orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getProducts`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getProductsPerformance = (
  fromDate: string,
  toDate: string,
  orgId: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
      orgId: orgId,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/getProductsPerformance`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getProductBaseLineData = (orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/getProductBaseLineData`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const updateProduct = (
  runid: string,
  productId: string,
  name: string,
  category: string,
  location: string,
  title: string,
  longTitle: string,
  description: string,
  price: string,
  finalUrl: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      runid: runid,
      productId: productId,
      name: name,
      category: category,
      location: location,
      title: title,
      longTitle: longTitle,
      description: description,
      price: price,
      finalUrl: finalUrl,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/updateProduct`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateProduct failed!!!");
      });
  });
};

export const updateProductInStock = (inStock: string, runid: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      runid: runid,
      inStock: inStock,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/updateProductInStock`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateProductInStock failed!!!");
      });
  });
};

export const addProduct = (
  productId: string,
  name: string,
  category: string,
  location: string,
  title: string,
  longTitle: string,
  description: string,
  price: string,
  finalUrl: string,
  inStock: string,
  orgId: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      productId: productId,
      name: name,
      category: category,
      location: location,
      title: title,
      longTitle: longTitle,
      description: description,
      price: price,
      finalUrl: finalUrl,
      inStock: inStock,
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/addProduct`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("addProduct failed!!!");
      });
  });
};

export const deleteProduct = (runId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      runId: runId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/deleteProduct`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("addProduct failed!!!");
      });
  });
};

export const loadProducts = (jsonArray: string, orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      json: jsonArray,
      orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/loadProducts`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("loadProducts failed!!!");
      });
  });
};

export const insertRequestToProduct = (
  orgId: string,
  productId: string,
  action_type: number
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
      productId: productId,
      action_type: action_type.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/insertRequestToProduct`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("insertRequestToProduct failed!!!");
      });
  });
};
export const checkIfHasRequestToProduct = (
  productId: string,
  action_type: number
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      productId: productId,
      action_type: action_type.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/checkIfHasRequestToProduct`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res.length > 0) {
          resolve(res[0].cnt);
        } else {
          resolve(-1);
        }
      })
      .catch(function (error) {
        resolve("checkIfHasRequestToProduct failed!!!");
      });
  });
};

export const insertRequestToChangeStatusCampaign = (
  orgId: string,
  campaignId: string,
  action_type: number
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
      campaignId: campaignId,
      action_type: action_type.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/insertRequestToUploadCampaign`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("insertRequestToUploadCampaign failed!!!");
      });
  });
};

export const checkIfHasRequestToCampaign = (
  campaignId: string,
  action_type: number
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      campaignId: campaignId,
      action_type: action_type.toString(),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/checkIfHasRequestToCampaign`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res.length > 0) {
          resolve(res[0].cnt);
        } else {
          resolve(-1);
        }
      })
      .catch(function (error) {
        resolve("checkIfHasRequestToCampaign failed!!!");
      });
  });
};

export const updateRequestToChangeStatusCampaign = (request_id: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      request_id: request_id,
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/data/updateRequestToChangeStatusCampaign`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateRequestToChangeStatusCampaign failed!!!");
      });
  });
};

export const getCampaigns = (
  id: string,
  fromDate: string,
  toDate: string,
  orgId: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      productId: id,
      from_date: fromDate,
      to_date: toDate,
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getCampaigns`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};
export const getWaitingCampaigns = (id: string, orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      productId: id,
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getWaitingCampaigns`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getLocations = (prefix: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      prefix: prefix,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getLocations`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const getChartData = (
  fromDate: string,
  toDate: string,
  campaign_id: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      from_date: fromDate,
      to_date: toDate,
      campaign_id: campaign_id,
    }).toString();

    const returnData = [["", "cost", "clicks", "impressions", "conversions"]];
    fetchRequest(
      `${config.url.API_URL}/data/getCampaignChartData`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          //debugger;

          for (var i = 0; i < res.length; i++) {
            var obj = res[i];
            var objDt = [
              obj["axisX"],
              obj["cost"],
              obj["clicks"],
              obj["impressions"],
              obj["conversions"],
            ];
            returnData.push(objDt);
          }

          resolve(returnData);
        } else {
          resolve(returnData);
        }
      })
      .catch(function (error) {
        resolve(returnData);
      });
  });
};

export const addCampaign = (
  orgId: string,
  accountId: string,
  productId: string,
  name: string,
  scheduled: number,
  channelType: number,
  category: string,
  location: string,
  title: string,
  longTitle: string,
  description: string,
  price: string,
  finalUrl: string,
  squareImage: string,
  marketingImage: string,
  logoImage: string,
  accentColor: string,
  mainColor: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
      accountId: accountId,
      productId: productId,
      name: name,
      scheduled: scheduled.toString(),
      channelType: channelType.toString(),
      category: category,
      location: location,
      title: title,
      longTitle: longTitle,
      description: description,
      price: price,
      finalUrl: finalUrl,
      squareImage: squareImage,
      marketingImage: marketingImage,
      logoImage: logoImage,
      accentColor: accentColor,
      mainColor: mainColor,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/addCampaign`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("addCampaign failed!!!");
      });
  });
};
export const updateCampaign = (
  runId: string,
  name: string,
  scheduled: number,
  channelType: number,
  category: string,
  location: string,
  title: string,
  longTitle: string,
  description: string,
  price: string,
  finalUrl: string,
  squareImage: string,
  marketingImage: string,
  logoImage: string,
  accentColor: string,
  mainColor: string
) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      runId: runId,
      name: name,
      scheduled: scheduled.toString(),
      channelType: channelType.toString(),
      category: category,
      location: location,
      title: title,
      longTitle: longTitle,
      description: description,
      price: price,
      finalUrl: finalUrl,
      squareImage: squareImage,
      marketingImage: marketingImage,
      logoImage: logoImage,
      accentColor: accentColor,
      mainColor: mainColor,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/updateCampaign`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("updateCampaign failed!!!");
      });
  });
};

export const getAccounts = (orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getAccounts`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res) {
          resolve(res);
        } else {
          resolve([]);
        }
      })
      .catch(function (error) {
        resolve([]);
      });
  });
};

export const insertIntoBaseLine = (orgId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      orgId: orgId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/insertIntoBaseLine`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        resolve(res.data);
      })
      .catch(function (error) {
        resolve("insertIntoBaseLine failed!!!");
      });
  });
};

export const getApplicationError = (context: string, objectId: string) => {
  return new Promise(async (resolve) => {
    var data = new URLSearchParams({
      context: context,
      objectId: objectId,
    }).toString();
    fetchRequest(`${config.url.API_URL}/data/getApplicationError`, "post", data)
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res && res.length > 0 && res[0].message) {
          resolve(res[0].message);
        } else {
          resolve("");
        }
      })
      .catch(function (error) {
        resolve("");
      });
  });
};
