import React, {
  useState,
  useMemo,
  useRef,
  KeyboardEventHandler,
  KeyboardEvent,
  FocusEventHandler,
} from "react";
import {
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonCard,
  IonCardContent,
} from "@ionic/react";

import {
  Control,
  Controller,
  FieldError,
  NestDataObject,
} from "react-hook-form";

import { ErrorMessage } from "../../../utils-ts/GlobalValidationFunctions";

import "./CustomAutocomplete.scss";

export interface autocompleteOption {
  value: string;
  display: string;
}

const CustomAutocomplete: React.FC<{
  name: string;
  label: string;
  control: Control;
  register: React.Ref<any>;
  getAutoCompleteItems: (
    searchString: string,
    digitalFieldNumber?: number
  ) => Promise<any>;
  optionSelected_callback?: (option: autocompleteOption) => void;
  skipItems?: autocompleteOption[];
  minInputLength?: number;
  maxResults?: number;
  isRequired?: boolean;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  clearInputAfterSelect?: boolean;
  digitalFieldNumber?: number;
}> = (props) => {
  const [autocompleteSearch, setAutocompleteSearch] = useState<string>(""); //for marging searched part in results
  const [autocompleteResult, setAutocompleteResult] = useState<
    autocompleteOption[] | null
  >(null);
  const [itemSelected, setItemSelected] = useState<boolean>(false); //for avoiding onChange after selecting
  const [showResults, setShowResults] = useState<boolean>(false); //for avoiding onChange after selecting

  const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null); //for arrow navigation
  const txtAddressRef = useRef<HTMLIonInputElement>(null); //for display only

  // Use memo will return the same object reference across all of the components renders.
  //Meaning we have a consistent referense for all change events and component renders.
  const promiseStore = useMemo<{ latestPromise: null | Promise<any> }>(
    () => ({ latestPromise: null }),
    []
  );

  const handleChange = async (e: CustomEvent) => {
    setFocusedItemIndex(null);
    if (itemSelected) {
      //input change triggered by selecting - no need to get new results
      setItemSelected(false);
      return;
    }

    //reset form ref
    //when serching any old selection is cleared
    props.control.setValue(props.name, null);

    //min chars to enter for results
    const min_length =
      props.minInputLength != null && props.minInputLength > 0
        ? props.minInputLength!
        : 2;
    if (!e.detail.value || e.detail.value.length < min_length) {
      setAutocompleteResult(null);
      setAutocompleteSearch("");
      setShowResults(false);
      return;
    }

    if (promiseStore.latestPromise) {
      // cancel the HTTP request here...
    }
    const localPromise = props.getAutoCompleteItems(
      e.detail.value,
      props.digitalFieldNumber
    );
    if (localPromise != null) {
      localPromise.then((result) => {
        // we'll compare the localPromise with the latestPromise.
        //If they're the same we'll udpate the state.
        //if this is not our lates request then it is not relevant any more
        if (localPromise === promiseStore.latestPromise) {
          if (Array.isArray(result)) {
            let autocompleteResult = result;
            if (props.skipItems) {
              const skip_values = props.skipItems.map((i) => {
                return i.value.toString();
              });
              autocompleteResult = result.filter((r: any) => {
                return skip_values.indexOf(r.value.toString()) < 0;
              });
            }
            setAutocompleteResult(autocompleteResult);
            setShowResults(true);
          } else if (typeof result == "string") {
            const apiResult = JSON.parse(result);
            let autocompleteResult = apiResult;
            if (props.skipItems) {
              const skip_values = props.skipItems.map((i) => {
                return i.value.toString();
              });
              autocompleteResult = apiResult.filter((r: any) => {
                return skip_values.indexOf(r.value.toString()) < 0;
              });
            }
            setAutocompleteResult(autocompleteResult);

            setShowResults(true);
          }
          setAutocompleteSearch(e.detail.value);
        }
      });
      promiseStore.latestPromise = localPromise;
    }
  };

  const selectItem = (
    display: string,
    value: string,
    event?: React.MouseEvent
  ) => {
    if (!!props.clearInputAfterSelect) {
      txtAddressRef.current!.value = "";
    } else {
      setItemSelected(true);
      txtAddressRef.current!.value = display; //for display only
      props.control.setValue(props.name, value); //for outer form ref -- real value
    }

    if (props.optionSelected_callback != null) {
      props.optionSelected_callback({ display, value });
    }

    //reset options
    setAutocompleteSearch("");
    setAutocompleteResult(null);
    setShowResults(false);
  };

  const handleFocusOut = (event: CustomEvent) => {
    setTimeout(() => {
      if (!!autocompleteResult) {
        //options are still displayed
        //so focusOut was NOT triggered by selecing from options
        //we need to hide autocomplete options
        setShowResults(false); //hide options
        const current_val = props.control.getValues(props.name);
        if (!current_val) {
          //real value is empty
          txtAddressRef.current!.value = ""; //reset display value
        }
      }
    }, 300);
  };

  const handelKeydown = (event: KeyboardEvent<HTMLIonInputElement>) => {
    //for arrow updown navigation
    if (!!autocompleteResult && autocompleteResult.length > 0) {
      const key_code = event.key;
      if (key_code == "Enter") {
        if (
          focusedItemIndex != null &&
          focusedItemIndex >= 0 &&
          focusedItemIndex < autocompleteResult.length
        ) {
          const selected = autocompleteResult[focusedItemIndex];
          if (selected != null) {
            selectItem(selected.display, selected.value);
          }
        }
      } else {
        if (key_code == "ArrowDown") {
          if (focusedItemIndex == null || focusedItemIndex < 0) {
            setFocusedItemIndex(0);
          } else if (focusedItemIndex + 1 < autocompleteResult.length) {
            setFocusedItemIndex(focusedItemIndex + 1);
          }
        } else if (key_code == "ArrowUp") {
          if (!!focusedItemIndex && focusedItemIndex > 0) {
            setFocusedItemIndex(focusedItemIndex - 1);
          }
        }
      }
    }
  };
  return (
    <React.Fragment>
      <IonItem
        color="light"
        className={
          "input-item " +
          (props.errors && props.errors[props.name] != null
            ? "item-invalid"
            : "")
        }
      >
        <IonLabel>
          {props.isRequired ? props.label + "*" : props.label}
        </IonLabel>
        <IonInput
          onIonChange={handleChange}
          onKeyDown={handelKeydown}
          onIonBlur={handleFocusOut}
          ref={txtAddressRef}
          name={props.name + "_display"}
          clearInput={true}
        />

        {/* hidden input for real value */}
        <Controller
          as={
            <IonInput
              className="input-hidden ion-no-padding ion-no-margin"
              name={props.name}
              ref={props.register}
            ></IonInput>
          }
          name={props.name}
          control={props.control}
          rules={{
            required: {
              value: !!props.isRequired,
              message: ErrorMessage("select from options"),
            },
          }}
        />
      </IonItem>

      {/* {!!autocompleteResult && autocompleteResult.length > 0 && ( */}
      <IonCard className="auto-complete-cont">
        <IonCardContent>
          <IonList>
            {autocompleteResult &&
              autocompleteResult
                .slice(
                  0,
                  !!props.maxResults
                    ? props.maxResults!
                    : autocompleteResult.length
                )
                .map((val, i) => (
                  <IonItem
                    key={i}
                    className="auto-complete-item"
                    lines={
                      i + 1 <
                      (!!props.maxResults &&
                      props.maxResults! < autocompleteResult.length
                        ? props.maxResults!
                        : autocompleteResult.length)
                        ? "full"
                        : "none"
                    }
                    button={true}
                    type="button"
                    onClick={selectItem.bind(null, val.display, val.value)}
                    color={i == focusedItemIndex ? "light" : undefined}
                  >
                    <IonLabel>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: val.display.replace(
                            autocompleteSearch,
                            "<b>" + autocompleteSearch + "</b>"
                          ),
                        }}
                      />
                    </IonLabel>
                  </IonItem>
                ))}
          </IonList>
        </IonCardContent>
      </IonCard>
      {/* )} */}
      <div className="input-alerts">
        {props.errors && props.errors[props.name] != null && (
          <p className="input-error">
            {(props.errors as any)[props.name].message}
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomAutocomplete;
