import React, { useContext, useEffect, useState } from "react";
import { getVendorLeads } from "../services/VendorDetailsService";
// import CustomDataGrid from "./utils/CustomElements/CustomDataGrid/CustomDataGrid";
// import { GridColDef } from "@material-ui/data-grid";
import { getDateReverseFormat } from "../utils-ts/GlobalDateFunctions";
import VendorDetailsContext from "../data/VendorDetails-context";
import { defaultStaticRanges } from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import { IonGrid } from "@ionic/react";
import { HeaderParameters } from "../data/Header-Context";
import CustomPage from "./utils/CustomElements/CustomPage";
import { text } from "ionicons/icons";
import { time } from "console";

const ActivityByDate: React.FC = () => {
  const VendorDetailsCxt = useContext(VendorDetailsContext);
  const [VendorLeads, setVendorLeads] = useState<any>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();

  const [showDateRangePicker, setShowDateRangePicker] =
    useState<boolean>(false);
  const [sums, setSums] = useState<any>([]);

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };
  useEffect(() => {
    if (VendorDetailsCxt.GeneralInfo == null) {
      VendorDetailsCxt.initVendorDetailsContext();
    }
  }, []);

  useEffect(() => {
    if (
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod &&
      VendorDetailsCxt.GeneralInfo?.DefaultPeriod !== "-1"
    ) {
      setSelectedStartDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .startDate
      );
      setSelectedEndDate(
        defaultStaticRanges[+VendorDetailsCxt.GeneralInfo?.DefaultPeriod! - 1]
          .endDate
      );
    } else if (
      VendorDetailsCxt.GeneralInfo?.SelectedFromDate &&
      VendorDetailsCxt.GeneralInfo?.SelectedToDate
    ) {
      setSelectedStartDate(VendorDetailsCxt.GeneralInfo?.SelectedFromDate);
      setSelectedEndDate(VendorDetailsCxt.GeneralInfo?.SelectedToDate);
    }
  }, [VendorDetailsCxt.GeneralInfo?.DefaultPeriod]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      getVendorLeads(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate)
      ).then((result) => {
        setVendorLeads(result);
      });
    }
  }, [selectedEndDate]);

  const columns = [
    {
      field: "name",
      title: "שם העסק",
      cellStyle: {
        width: "200px",
        minWidth: "200px",
      },
    },
    {
      field: "contact_phone",
      title: "טלפון",
      type: "numeric",
      cellStyle: {
        width: "5%",
      },
    },
    {
      field: "phone",
      title: "טלפון נוסף",
      type: "numeric",
      cellStyle: {
        width: "5%",
      },
    },
    {
      field: "contact",
      title: "איש קשר",
      cellStyle: {
        width: "5%",
      },
    },
    {
      field: "dateInsert",
      title: "תאריך",
      type: "date",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "insertTime",
      title: "שעה",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "DomainFrom",
      title: "קוד מקור",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "lead_origin_desc",
      title: "מקור",
      type: "numeric",
      cellStyle: {
        width: "5%",
      },
    },
    {
      field: "marketing_desc",
      title: "תיאור השיווקי",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "Remarks",
      title: "הערה",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "LeadStatus",
      title: "מועמד לליד",
      type: "numeric",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "Reject_desc",
      title: "סיבת הדחייה",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "status_desc",
      title: "סטטוס ליד במערכת מכירות",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "b144Status",
      title: "סטטוס לקוח",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "dateAbandon",
      title: "תאריך נטישה",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "prodmain_desc",
      title: "התעניין במוצר",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "isSpam",
      title: "מאושר דוור",
      cellStyle: {
        width: "2%",
      },
    },
    {
      field: "utm_source",
      title: "מקור UTM",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "utm_medium",
      title: "תווך UTM",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "utm_term",
      title: "תנאי UTM",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "utm_campaign",
      title: "קמפיין UTM",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "utm_content",
      title: "תוכן UTM",
      cellStyle: {
        width: "200px",
      },
    },
    {
      field: "url",
      title: "URL",
      cellStyle: {
        width: "200px",
      },
    },
  ];

  return (
    <CustomPage headerParameters={headerProps} pageName={"Home"}>
      <IonGrid>
        {VendorDetailsCxt &&
          VendorDetailsCxt.GeneralInfo != null &&
          selectedStartDate &&
          selectedEndDate && (
            <CustomMaterialTable
              rows={VendorLeads}
              columns={columns}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              onSearchClick={(startDate: Date, endDate: Date) => {
                setSelectedStartDate && setSelectedStartDate(startDate);
                setSelectedEndDate && setSelectedEndDate(endDate);
                setShowDateRangePicker(false);
                getVendorLeads(
                  getDateReverseFormat(selectedStartDate),
                  getDateReverseFormat(selectedEndDate)
                ).then((result) => {
                  setVendorLeads(result);
                });
              }}
              summaryTbl={sums}
              title={"לידים"}
              isChangDefualteDate={true}
            />
          )}
      </IonGrid>
    </CustomPage>
  );
};

export default ActivityByDate;
