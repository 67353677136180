import React from "react";
import { FieldError, NestDataObject } from "react-hook-form";
import { validateForm } from "../../../utils-ts/GlobalValidationFunctions";

const CustomForm: React.FC<{
  onSubmit: () => void;
  errors: NestDataObject<Record<string, any>, FieldError>;
}> = (props) => {
  return (
    <form
      noValidate
      onSubmitCapture={(e) => {
        validateForm(props.errors);
      }}
      onSubmit={props.onSubmit}
      onKeyPress={(event: any) => event.charCode == 13 && props.onSubmit()}
    >
      {props.children}
    </form>
  );
};

export default CustomForm;
