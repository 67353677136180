import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import ErrorBoundary from "./utils-ts/ErrorBoundary";
import { toast } from "react-toastify";
import { IsNavigatorOnLine } from "./utils-ts/OnLineStatus";
import CustomToast from "./components/utils/CustomElements/CustomToast";
import { AuthContextProvider } from "./data/AuthContextProvider";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { VendorDetailsProvider } from "./data/VendorDetailsProvider";
import { firebaseRegister } from "./utils-ts/firebaseRegister";

var toastId: any = null;

const StatusIndicator = () => {
  const isOnline = IsNavigatorOnLine();
  if (!isOnline) {
    toastId = toast.error("לא נמצא חיבור לרשת", {
      autoClose: false,
      closeButton: false, // Remove the closeButton
    });
  } else if (toastId !== null) {
    toast.update(toastId, {
      render: "חיבור לרשת שוחזר",
      type: toast.TYPE.INFO,
      autoClose: 1000,
      closeButton: null,
    });
    toastId = null;

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return <></>;
};

ReactDOM.render(
  <ErrorBoundary>
    <StatusIndicator />
    <CustomToast />
    <AuthContextProvider>
      <VendorDetailsProvider>
        <App />
      </VendorDetailsProvider>
    </AuthContextProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

defineCustomElements(window);

firebaseRegister();
