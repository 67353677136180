import React from "react";

export interface userData {
  Token: string | null;
  Email: string | null;
  Id: string | null;
  Name: string | null;
}

export interface GeneralInfo {
  DefaultPeriod: string;
  SelectedToDate?: Date | undefined;
  SelectedFromDate?: Date | undefined;
}

const VendorDetailsContext = React.createContext<{
  GeneralInfo: GeneralInfo | undefined;
  loading: boolean;
  initVendorDetailsContext: () => void;
  setDefaultPeriod: (ID: any) => void;
  setSelectedDate: (SelectedToDate: Date, SelectedFromDate: Date) => void;
  setLoader: (loading: boolean) => void;
}>({
  GeneralInfo: {
    DefaultPeriod: "",
    SelectedToDate: undefined,
    SelectedFromDate: undefined,
  },
  loading: false,
  initVendorDetailsContext: () => {},
  setDefaultPeriod: () => {},
  setSelectedDate: () => {},
  setLoader: () => {},
});

export default VendorDetailsContext;
