// export const coupon = require("./coupons/couponPresent.jpg").default;
// export const covidLogo = require("./logos/covid-logo.png").default;
// export const googleAndB144 = require("./logos/google-b144.png").default;
// export const logoMobile = require("./logos/logob-144-mobile.svg").default;
// export const logoNegative = require("./logos/logob-144-mobile-negative.svg").default;

export const trash = require("./icons/delete.svg").default;
export const trashDark = require("./icons/trash-dark.svg").default;
export const tableChart = require ("./icons/table_chart.svg").default;
// export const businessCardWhite = require("./icons/business-card-white.svg").default;
// export const cupon = require("./icons/cupon.svg").default;
// export const product = require("./icons/product.svg").default;
// export const reviewWhite = require("./icons/review-white.svg").default;
// export const statisticsWhite = require("./icons/statistics-white.svg").default;
// export const update = require("./icons/update.svg").default;
// export const picture = require("./icons/picture.svg").default;
export const home = require("./icons/home.svg").default;
// export const businessCard = require("./icons/business-card.svg").default;
// export const help = require("./icons/help.svg").default;
// export const inbox = require("./icons/inbox.svg").default;
// export const inboxWhite = require("./icons/inbox-white.svg").default;
// export const review = require("./icons/review.svg").default;
// export const shopping = require("./icons/shopping-cart.svg").default;
// export const statistics = require("./icons/statistics.svg").default;
export const settings = require("./icons/settings.svg").default;
export const settingsBlack = require("./icons/settings-black.svg").default;
// export const onlineWebsiteBuilder = require("./icons/online-website-builder.svg").default;
// export const laptop = require("./icons/laptop.svg").default;
// export const exit = require("./icons/exit.svg").default;
export const iconEditWhite = require("./icons/icon-edit-white.svg").default;
// export const send = require("./icons/send.svg").default;
// export const pencil = require("./icons/pencil.svg").default;
// export const document = require("./icons/document.svg").default;
// export const phone = require("./icons/phone.svg").default;
// export const adress = require("./icons/adress.svg").default;
// export const edit = require("./icons/edit.svg").default;
// export const colors = require("./icons/colors.svg").default;
// export const heart = require("./icons/heart-blue.svg").default;
export const alert = require("./icons/alert.svg").default;
// export const replaceImageWhite = require("./icons/replace-image-white.svg").default;
export const closeBlack = require("./icons/close-black.svg").default;
export const imageBlack = require("./icons/image-black.svg").default;
export const imageWhite = require("./icons/image-white.svg").default;
export const notificationsOn = require("./icons/notifications-on.svg").default;
export const notificationsOff = require("./icons/notifications-off.svg").default;
export const download = require("./icons/download.svg").default;
export const downloadBlack = require("./icons/download-black.svg").default;
// export const mailOpenBlue = require("./icons/mail_open.svg").default;
export const menu = require("./icons/menu.svg").default;
// export const avatar = require("./icons/avatar.svg").default;
// export const arrowBack = require("./icons/arrowBack.svg").default;

// export const blankImageIcon = require("./icons/blank-image-icon.svg").default;
// export const blankProductIcon = require("./icons/blank-product-icon.svg").default;
// export const blankProductIconOrange = require("./icons/blank-orange-product-icon.svg").default;
// export const blankProductIconGreen = require("./icons/blank-green-product-icon.svg").default;
// export const blankProductIconRed = require("./icons/blank-red-product-icon.svg").default;

// export const facebook = require("./icons/Facebook.svg").default;
// export const instegram = require("./icons/instegram.svg").default;
// export const google = require("./icons/google.svg").default;
// export const taboola = require("./icons/taboola.svg").default;

// statistics icons

// export const donut_small = require("./icons/donut_small.svg").default;
// export const fact_check = require("./icons/fact_check.svg").default;
// export const important_devices = require("./icons/important_devices.svg").default;
// export const touch_app = require("./icons/touch_app.svg").default;
// export const ring_volume = require("./icons/ring_volume.svg").default;

// inbox icons

// export const inboxCustomSender = require("./icons/inbox-custom-sender.svg").default;

// user settings

// export const ballot = require("./icons/ballot.svg").default;
// export const sentimentSatisfied = require("./icons/sentiment_satisfied.svg").default;
// export const notifications = require("./icons/notifications.svg").default;
// export const markunreadMailbox = require("./icons/markunread_mailbox.svg").default;

// help

// export const facebookWhite = require("./icons/facebook-white.svg").default;
// export const googleWhite = require("./icons/google-white.svg").default;
// export const helpCenter = require("./icons/help_center.svg").default;
// export const message = require("./icons/message.svg").default;
// export const supportAgent = require("./icons/support_agent.svg").default;
// export const support = require("./icons/support.svg").default;

// export const tip1 = require("./tips/app_tip_sale.jpg").default;
// export const tip2 = require("./tips/app_tip_stars.jpg").default;

// export const welcomeNewUser = require("./banners/V2_PopAnimation_D_500x330_whiteBG.gif").default;
// export const welcomeNewUserMob = require("./banners/V2_PopAnimation_M_300x300_whiteBG.gif").default;
