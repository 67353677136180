import React, { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Palette } from "@devexpress/dx-react-chart";
import "./SummeryTable.scss";
import { isPlatform } from "@ionic/core";
import { isMobile } from "react-device-detect";

export interface summaryData {
  title: string;
  value: any;
}

const SummaryTable: React.FC<{
  summaryTbl: { data: summaryData[]; summaryType?: string };
}> = ({ ...props }) => {
  const [summaryData, setSummaryData] = useState<summaryData[]>(
    props.summaryTbl.data ? props.summaryTbl.data : []
  );

  const [summaryType, setSummaryType] = useState<string>(
    props.summaryTbl.summaryType ? props.summaryTbl.summaryType : ""
  );

  const [summaryTotal, setSummaryTotal] = useState<number>(0);

  useEffect(() => {
    if (summaryType === "calcTotal" && summaryData.length > 0) {
      if (typeof summaryData[0].value !== "number") {
        setSummaryTotal(
          summaryData
            .map((summeryItem) =>
              parseFloat(summeryItem.value.replace(/,/g, ""))
            )
            .reduce((a: number, b: number) => a + b, 0)
        );
      }
    }
  }, [summaryData, summaryType]);

  return (
    <React.Fragment>
      <ul className={"summary-table " + summaryType}>
        {summaryData &&
          summaryData.length > 0 &&
          summaryData.map((m: any, index: number) => {
            const parsedValue =
              typeof summaryData[0].value !== "number"
                ? parseFloat(m.value && m.value.replace(/,/g, ""))
                : summaryData[0].value;
            const summeryTotal = summaryTotal - parsedValue;

            const graphData = [
              {
                title: m.title,
                value: parsedValue,
              },
              {
                title: "total-value",
                value: summeryTotal,
              },
            ];
            return (
              <li key={index}>
                {!isMobile && summaryType === "calcTotal" && (
                  <Chart data={graphData} width={70} height={70}>
                    <Palette scheme={["#16254f", "#dee6ea"]} />
                    <PieSeries valueField="value" argumentField="title" />
                  </Chart>
                )}
                <div className="info-data">
                  <p className="bold-text">
                    {m.value ? m.value : "-"}
                    {props.summaryTbl.summaryType === "calcTotal" && (
                      <span className="small-title light-text"> ₪ </span>
                    )}
                  </p>
                  <p>{m.title}</p>
                </div>
              </li>
            );
          })}
      </ul>
      <div className="card-item-divider"></div>
    </React.Fragment>
  );
};
export default SummaryTable;
